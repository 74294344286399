import React from "react";
import firebase from "firebase";

export const RestaurantContext = React.createContext(null);

const EventEmitter = require('events');

class RestaurantEmitter extends EventEmitter {}

export const restaurantEmitter = new RestaurantEmitter();

let restaurants = null;

export const setCachedRestaurants = (rs) => 
{
    if (rs !== restaurants) {
        restaurants = rs;
        restaurantEmitter.emit('changed');
    }
}

export const getCachedRestaurants = () => 
{
    return restaurants;
}

export const onRestaurantUpdated = (r) => {

    // Update local cache and UI
    const clonedRestaurants = [...restaurants];
    const index = clonedRestaurants.findIndex(el => el.id === r.id);
    clonedRestaurants.splice(index, 1, r);
    setCachedRestaurants(clonedRestaurants);
    
    // Post to server
    firebase.auth().currentUser.getIdToken()
        .then(token => {
            const payload = {
                'id': r.id,
                'description': r.description,
                'weekdaySettings': r.weekdaySettings,
                'aLaCarteMenu': r.dishes,
                'minimumDelayMinutes': r.minimumDelayMinutes,
                'postalTown': r.postalTown,
                'streetAddress': r.streetAddress,
                'lng': r.lng,
                'lat': r.lat
            }

            fetch('api/restaurant', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
                .then(async response => {
                    if (!response.ok) {
                        alert("Request failed")
                    }
                    else
                    {
                        // response ok, use new values from server
                        r = dtoToRest(await response.json());
                        console.log(r.name);

                        // Update local cache and ui with response from server
                        const clonedRestaurants = [...restaurants];
                        const index = clonedRestaurants.findIndex(el => el.id === r.id);
                        clonedRestaurants.splice(index, 1, r);
                        setCachedRestaurants(clonedRestaurants);
                    }
                })
                .catch(error => {
                    alert("Network error")
                });
        });

   

}

export function dtoToRest(data) {
    return ({
        id: data.id,
        name: data.name,
        weekdaySettings: data.weekdaySettings,
        dishes: data.aLaCarteMenu,
        description: data.description,
        minimumDelayMinutes: data.minimumDelayMinutes,
        streetAddress: data.streetAddress,
        postalTown: data.postalTown,
        lat: data.lat,
        lng: data.lng
    });
}