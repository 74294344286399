import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {Link as MaterialLink } from "@material-ui/core"
import Button from "@material-ui/core/Button";

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
                if (!isSignedIn) {
                    return this.anonymousView();
                } else {
                    return this.authenticatedView(user.displayName);
                }
            }}
        </FirebaseAuthConsumer>
    }

    authenticatedView(userName) {
        return (<Fragment>
            <Button component={Link} to={'/admin/account'} color={"inherit"}>{userName}</Button>
        </Fragment>);

    }

    anonymousView() {
        return (<Fragment>
            <MaterialLink component={Link} to={'/admin/account'} color={"inherit"}>Login</MaterialLink>
        </Fragment>);
    }
}
