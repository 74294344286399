import {greenTheme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Button, MuiThemeProvider} from "@material-ui/core";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {TaMedBody, TaMedHeading, TaMedIntermediateHeading} from "./ta-med-styles/TaMedStyles";
import React from "react";

export default function PrivacyPolicy(){
    return <MuiThemeProvider theme={greenTheme}>
        <CssBaseline/>
        <Box><Container>
            <Grid container spacing={3}>
                <Grid item xs={1} sm={4}/>
                <Grid item xs={10} sm={4}>
                    <Box mx={2} my={8} display={"flex"} justifyContent={"center"}>
                        <img width={"100%"} src={"/images/logo.svg"} alt={"ta-med logo"}/>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} >
                        <Typography align={"center"} variant={"h6"}>Lunch utan kö och kassabetalning</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1} sm={4}/>

                <Grid item xs={12}>
                    <Box m={4}>
                        {/*<TaMedHeading>Take away lunch</TaMedHeading>*/}
                        <Grid container>
                            <Grid item xs={12} >
                                <TaMedIntermediateHeading>Integritetspolicy</TaMedIntermediateHeading>
                                <Box mt={1}>
                                    <Typography variant={"body1"}>Vi lagrar användares:</Typography>
                                    <Typography variant={"body1"}>E-postadress, för att tillhandahålla inloggning och kunna skicka e-post med rapporter och marknadsföring.</Typography>
                                    <Typography variant={"body1"}>Namn, för att kunna märka beställningar och säkerställa att de mottagits av rätt person.</Typography>
                                    <Typography variant={"body1"}>Telefonnummer vid Swishbetalning, för att kunna göra återbetalningar.</Typography>
                                    <Typography variant={"body1"} paragraph={true}>Information om transaktioner, för att kunna tillhandahålla historik, rapporter, kvitton och lösa eventuella problem.</Typography>
                                    <TaMedBody>Vi säljer inte kunduppgifter vidare till tredje part.</TaMedBody>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    </MuiThemeProvider>
}
