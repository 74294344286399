////export const firebaseConfig = {
////    apiKey: "AIzaSyBc84uUPT1IXHFCU0notV6eXb3u_k8I9Dg",
////    authDomain: "ta-med-prod.firebaseapp.com",
////    databaseURL: "https://ta-med-prod.firebaseio.com",
////    projectId: "ta-med-prod",
////    storageBucket: "ta-med-prod.appspot.com",
////    messagingSenderId: "205250708895",
////    appId: "1:205250708895:web:281ad63b450979dee069aa",
////    measurementId: "G-BC67RJXGDH"
////};


export const firebaseConfig = {
    apiKey: "AIzaSyD1_I6G56CRxEl2Lp39gPjB0diBeAkrUGc",
    authDomain: "ta-med-43e15.firebaseapp.com",
    databaseURL: "https://ta-med-43e15.firebaseio.com",
    projectId: "ta-med-43e15",
    storageBucket: "ta-med-43e15.appspot.com",
    messagingSenderId: "111753687088",
    appId: "1:111753687088:web:853fde350ca1b4ba9efdf5",
    measurementId: "G-VH5F4KLR86"
};


// // This is our firebaseui configuration object
// const uiConfig = ({
//     signInSuccessUrl: '/',
//     signInOptions: [
//         window.firebase.auth.GoogleAuthProvider.PROVIDER_ID
//     ],
//     tosUrl: '/terms-of-service' // This doesn't exist yet
// })


// // This sets up firebaseui
// const ui = new firebaseui.auth.AuthUI(window.firebase.auth())
//
// // This adds firebaseui to the page
// // It does everything else on its own
// export const startFirebaseUI = function (elementId) {
//     ui.start(elementId, uiConfig)
// }

