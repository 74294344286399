import React, {useState} from "react";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {TaMedHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import 'moment/locale/sv'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import "firebase/auth";
import Button from "@material-ui/core/Button";
import {onRestaurantUpdated, RestaurantContext} from "./RestaurantContext";
import GoogleMapReact from 'google-map-react';
import './Marker.css'
import Marker from "./Marker";

const WeekdayItem = ({item = {defaultOpen: false, defaultOpenTime: '00:00', defaultCloseTime: '00:00', defaultLunchStartTime: '00:00', defaultLunchEndTime: '00:00'},
                     index = -1,
                     onUpdate}) =>
{
    moment.locale('sv');

    const [state, setState] = React.useState(item);

    if (state !== item)
    {
        setState(item);    
    }
    
    function storeDayConfig(newVar) {
        onUpdate({index: index, value: newVar});
    }
    
    const _with = (oldStructure, newEntries) => {
        return Object.assign({}, oldStructure, newEntries);
    }

    const handleLunchStartChange = event => {
        const newState = _with(state, {defaultLunchStartTime: event.target.value});

        setState(newState);
        storeDayConfig(newState);
    }

    const handleLunchEndChange = event => {
        const newState = _with(state, {defaultLunchEndTime: event.target.value});

        setState(newState);
        storeDayConfig(newState);
    }

    const handleCloseTimeChange = event => {
        const newState = _with(state, {defaultCloseTime: event.target.value});

        setState(newState);
        storeDayConfig(newState);
    }

    const handleOpenTimeChange = event => {
        const newState = _with(state, {defaultOpenTime: event.target.value});

        setState(newState);
        storeDayConfig(newState);
    };
    const handleOpenChanged = event => {
        const newState = _with(state, {defaultOpen: event.target.checked});

        setState(newState);
        storeDayConfig(newState);
    };
    
    return <ListItem>
        <Grid spacing={2} container>
            <Grid item xs={12} lg={2} ><Box ml={{xs:0, lg:2}}><Typography variant={"subtitle1"} >{moment.weekdays(index + 1)}</Typography></Box></Grid>
            <Grid item xs={10}>
                <FormControlLabel
                    control={<Checkbox checked={state.defaultOpen} onChange={handleOpenChanged} name="checkedA"/>}
                    label="Öppet"
                />

            </Grid>
            <Grid item lg={2}/>
            <Grid item xs={12} lg={5}>
                <FormGroup row>
                    <FormLabel>Beställningar</FormLabel>
                    <Box ml={2}><TextField
                        disabled={!state.defaultOpen}
                        id="time1"
                        label="Första"
                        type="time"
                        value={state?.defaultOpenTime ?? ''}
                        error={false}
                        onChange={handleOpenTimeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    /></Box>
                    <Box ml={2}><TextField
                        disabled={!state.defaultOpen}
                        id="time2"
                        label="Sista"
                        type="time"
                        value={state.defaultCloseTime ?? undefined}
                        error={false}
                        onChange={handleCloseTimeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    /></Box>
                </FormGroup>
            </Grid>
            <Grid item xs={12} lg={4}><FormGroup row>
                <FormLabel>Lunchmeny</FormLabel>
                <Box ml={2}><TextField
                    disabled={!state.defaultOpen}
                    id="lunch1"
                    label="Från"
                    type="time"
                    value={state.defaultLunchStartTime ?? undefined}
                    error={false}
                    onChange={handleLunchStartChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                /></Box>
                <Box ml={2}><TextField
                    disabled={!state.defaultOpen}
                    id="lunch2"
                    label="Till"
                    type="time"
                    value={state.defaultLunchEndTime ?? undefined}
                    error={false}
                    onChange={handleLunchEndChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                /></Box>
            </FormGroup></Grid>
            <Grid item lg={1}/>
        </Grid>
    </ListItem>
}
const AnyReactComponent = ({ text }) => <div>{text}</div>;
    
const SettingsContentView = (props) =>
{
    const [lastRestaurant, setLastRestaurant] = useState(props.restaurant);
    const [restaurantState, setRestaurantState] = useState(props.restaurant);
    const [descriptionState, setDescriptionState] = useState(props.restaurant.description);
    const [addressState, setAddressState] = useState(props.restaurant.streetAddress);
    const [postalTownState, setPostalTownState] = useState(props.restaurant.postalTown);
    const [waitTimeState, setWaitTimeState] = useState(props.restaurant.minimumDelayMinutes ? props.restaurant.minimumDelayMinutes : '');
    
    const handleUpdateWeekdayItem = ({index, value}) => {
        const newList = (restaurantState?.weekdaySettings??[]).map((u, ui) => ui !== index ? u : value);
        
        const newState = Object.assign({}, restaurantState, {weekdaySettings: newList});
        setRestaurantState(newState);
        props.onRestaurantUpdated(newState);
    }
    
    const handleDescriptionChange = event => {
        setDescriptionState(event.target.value);
    }
    
    const handleAddressChange = event => {
        setAddressState(event.target.value);
    }

    const handlePostalTownChange = event => {
        setPostalTownState(event.target.value);
    }

    function handleWaitTimeChange(event) {
        const numberString = /\d/.test(event.target.value) ? parseInt(event.target.value).toString() : "";
        
        setWaitTimeState(numberString);
    }

    const miscFormSubmitted = (event) => {
        const newState = Object.assign({}, restaurantState, {description: descriptionState, minimumDelayMinutes: parseInt(waitTimeState)});
        setRestaurantState(newState);
        props.onRestaurantUpdated(newState);
        event.preventDefault();
    }

    const addressFormSubmitted = (event) => {
        const newState = Object.assign({}, restaurantState, {streetAddress: addressState, postalTown: postalTownState});
        setRestaurantState(newState);
        props.onRestaurantUpdated(newState);
        event.preventDefault();
    }

    const saveButtonEnabled = !(descriptionState === restaurantState.description && waitTimeState === restaurantState.minimumDelayMinutes);

    const addressSaveButtonEnabled = !(addressState === restaurantState.streetAddress && postalTownState === restaurantState.postalTown);
    
    
    if (props.restaurant !== lastRestaurant)
    {
        setLastRestaurant(props.restaurant);
        setRestaurantState(props.restaurant)
        setDescriptionState(props.restaurant.description);
        setAddressState(props.restaurant.streetAddress);
        setPostalTownState(props.restaurant.postalTown);
        setWaitTimeState(props.restaurant.minimumDelayMinutes);
    }

    const defaultCenter = {
        lat: restaurantState.lat,
        lng: restaurantState.lng
    };
    const defaultZoom = 17;

    function _onClick(obj){ console.log(obj.x, obj.y, obj.lat, obj.lng, obj.event);}
    
    return <>
    <Box mt={2}>
            <Paper>
                <Box p={2}>
                    <Typography variant={"h6"}> 
                        Allmänt
                    </Typography>
                </Box>

                <Box py={2} px={4}>
                    <form onSubmit={miscFormSubmitted}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                    <TextField
                        fullWidth={true}
                        id="outlined-multiline-static"
                        label="Om restaurangen"
                        multiline
                        rows={2}
                        value={descriptionState ?? undefined}
                        onChange={handleDescriptionChange}
                        variant="outlined"
                    /></Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-waittime" 
                                       // type="number"
                                       label="Kortast väntetid (min)" 
                                       onChange={handleWaitTimeChange}
                                       value={waitTimeState ?? undefined}
                                       margin="normal" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                    <Button disabled={! saveButtonEnabled} variant={"contained"} type={"submit"}>Spara</Button>
                        </Grid></Grid>
                    </form>
                </Box>
                <Box p={2}>
                    <Typography variant={"h6"}>
                        Plats
                    </Typography>
                </Box>
                
                <Box py={2} px={4}>
                    <form onSubmit={addressFormSubmitted}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                <TextField
                                    fullWidth={true}
                                    id="outlined-multiline-static"
                                    label="Address"
                                    value={addressState ?? undefined}
                                    onChange={handleAddressChange}
                                    variant="outlined"
                                />
                                </Box>
                                <Box pt={2}>
                                <TextField
                                    fullWidth={true}
                                    id="outlined-multiline-static"
                                    label="Ort"
                                    value={postalTownState ?? undefined}
                                    onChange={handlePostalTownChange}
                                    variant="outlined"
                                />
                                </Box>
                                <Box pt={2}>
                                <Button disabled={! addressSaveButtonEnabled} variant={"contained"} type={"submit"}>Spara</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <div style={{ display: 'inline-block', position:'relative', width: '100%' }}>
                                    <div style={{marginTop: '50%'}}/>
                                    <div style={{position: 'absolute', top:0, left:0,right:0,bottom:0}}> 
                                    <GoogleMapReact
                                        onClick={_onClick}
                                        bootstrapURLKeys={{ key: 'AIzaSyDbcFsDVtX7Ps5X6VQZukSb9UGdca621H4' }}
                                        // defaultCenter={defaultCenter}
                                        defaultZoom={defaultZoom}
                                        center={defaultCenter}
                                    >
                                        <Marker
                                            lat={defaultCenter.lat}
                                            lng={defaultCenter.lng}
                                            name="My Marker"
                                            color="blue"
                                        />
                                        
                                        {/*<AnyReactComponent*/}
                                        {/*    lat={59.955413}*/}
                                        {/*    lng={30.337844}*/}
                                        {/*    text="My Marker"*/}
                                        {/*/>*/}
                                    </GoogleMapReact>
                                    </div>
                                </div>                
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                
                <Box>
                    
                </Box>
                
                <Box p={2}>
                    <Typography variant={"h6"}>
                        Mall för en standardvecka
                    </Typography>
                </Box>
                <Box pb={4}>
                <List>
                    {(restaurantState.weekdaySettings).map((item, index) => (
                        item === undefined ? <div/> :
                            <WeekdayItem item={item} key={index} index={index} onUpdate={handleUpdateWeekdayItem}/>
                    ))}
                </List>
                </Box>
            </Paper>
        </Box>
    </>
}

const LoadingBadge = () =>
{
    return <><Box mt={4}>Laddar...</Box></>
}

const AuthenticatedView = (props) =>
{
    const [lastUser, setLastUser] = useState(props.user);
    
    const [restaurant, setRestaurant] = useState(props.restaurant);

    
    if (restaurant !== props.restaurant)
    {
        // Reload restaurant
        setRestaurant(props.restaurant);
    }
    
    if (props.user !== lastUser)
    {
        setLastUser(props.user || restaurant === undefined);
    }
    
    if (! restaurant) {
        return <LoadingBadge/>
    }

    return <>
        <Box mt={4}>
            <Typography variant={"h6"} >
            {restaurant.name}
            </Typography>
        </Box>

       
        
        <SettingsContentView restaurant={restaurant} onRestaurantUpdated={onRestaurantUpdated}/>
    </>
}

const NotAuthenticated = (props) => {
    return <>
        <Box mt={4}>
            <div><TaMedHeading>Du är inte inloggad</TaMedHeading></div>
        </Box>
    </>;
}

export default function Restaurant() {
    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            if (!isSignedIn)
                return <NotAuthenticated/>
            else
                return <RestaurantContext.Consumer>
                    {(selectedRestaurant) => (
                        selectedRestaurant ? <AuthenticatedView user={user} restaurant={selectedRestaurant}/>
                            : <>No restaurant</>
                    )}

                </RestaurantContext.Consumer>
        }
        }
    </FirebaseAuthConsumer>
}
