import React, {useState} from "react";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {TaMedHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import 'moment/locale/sv'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "firebase/auth";
import {ALaCarteDishListItem, EditDish} from "./DishListItem";
import Button from "@material-ui/core/Button";
import {uuidv4} from "./helpers";
import {onRestaurantUpdated, RestaurantContext} from "./RestaurantContext";
import {useConfirm} from "material-ui-confirm";

const ALaCarteContentView = (props) =>
{
    const [lastRestaurant, setLastRestaurant] = useState(props.restaurant);
    const [restaurantState, setRestaurantState] = useState(props.restaurant);
    const [addFormVisible, setAddFormVisible] = React.useState(false);
    
    if (props.restaurant !== lastRestaurant)
    {
        setLastRestaurant(props.restaurant);
        setRestaurantState(props.restaurant);
    }
    
    const enabled = true;
    
    const setListAndUpdateBackend = newList => {
        const tempState = Object.assign({}, restaurantState);
        tempState.dishes = newList;
        setRestaurantState(tempState);
        props.onRestaurantUpdated(tempState);
    }

    const handleSubmitDish = value => {
        if (value) {
            const newValue = Object.assign({},
                value,
                {id: uuidv4()},
            )

            setListAndUpdateBackend((restaurantState?.dishes ?? []).concat(newValue));
            setAddFormVisible(false);
        }
    };

    const handleUpdate = value => {
        const newList = (restaurantState?.dishes??[]).map(u => u.id !== value.id ? u : value);
        setListAndUpdateBackend(newList)
    }
    const confirm = useConfirm();

    const handleDelete = key => {
        confirm({description: 'Vill du radera maträtten?'})
            .then(async () => {
                const newList = (restaurantState?.dishes ?? []).filter(u => u.id !== key);
                setListAndUpdateBackend(newList);
            })
            .catch(() => {
                console.log("Aborted delete dish")
            });
    }
    
    const handleMoveUp = key => {
        console.log("request to move up");
        const clonedDishes = [...restaurantState?.dishes];
        const index = clonedDishes.findIndex(el => el.id === key);
        clonedDishes.splice(index - 1, 0, clonedDishes.splice(index, 1)[0]);
        setListAndUpdateBackend(clonedDishes);
    }

    
    return <>
        <Box mt={2} mb={4}>
            <Grid container>
                <Grid item xs={12} lg={6}>
            <Paper>
                <Box p={2}>
                    <Typography variant={"h6"}>
                        À la carte meny (alltid tillgänglig)
                    </Typography>
                </Box>
                <Box pb={4} mr={2} ml={{xs:0, lg:2}}>
                    <List>
                        <List>
                            {(restaurantState?.dishes ?? []).map((item, index) => (
                                <ALaCarteDishListItem item={item} key={item.id} onUpdate={handleUpdate} onMoveUp={index === 0 ? null : handleMoveUp}
                                                   onDeleteItem={handleDelete}/>
                            ))}
                        </List>
                        {!addFormVisible &&
                        <Box mx={2} display={"flex"} justifyContent={"flex-start"}>
                            <Button disabled={!enabled} variant={"contained"} onClick={() => setAddFormVisible(true)}>Lägg
                                till maträtt</Button>
                        </Box>
                        }
                        {addFormVisible && <Box m={2}><EditDish onSubmit={handleSubmitDish}
                                                                onCancel={() => setAddFormVisible(false)}/></Box>}

                    </List>
                </Box>
            </Paper>
                </Grid>
            </Grid>
        </Box>
    </>
}

const LoadingBadge = () =>
{
    return <><Box mt={4}>Laddar...</Box></>
}

const AuthenticatedView = (props) =>
{
    if (! props.restaurant) {
        return <LoadingBadge/>
    }

    return <>
        <Box mt={4}>
            <Typography variant={"h6"} >
            {props.restaurant.name}
            </Typography>
        </Box>
        <ALaCarteContentView restaurant={props.restaurant} onRestaurantUpdated={onRestaurantUpdated}/>
    </>
}

const NotAuthenticated = (props) => {
    return <>
        <Box mt={4}>
            <div><TaMedHeading>Du är inte inloggad</TaMedHeading></div>
        </Box>
    </>;
}

export default function ALaCarte() {
    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            if (!isSignedIn)
                return <NotAuthenticated/>
            else
                return <RestaurantContext.Consumer>
                {(selectedRestaurant) => (
                    selectedRestaurant ? <AuthenticatedView user={user} restaurant={selectedRestaurant}/>
                        : <>No restaurant</>
                )}

            </RestaurantContext.Consumer>
        }
        }
    </FirebaseAuthConsumer>
}

