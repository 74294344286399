import React, {useEffect, useState} from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import Grid from "@material-ui/core/Grid";
import {TaMedBody, TaMedHeading, TaMedIntermediateHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import moment from "moment";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {DailyDishListItem, EditDish} from "./DishListItem";
import {uuidv4} from "./helpers";
import {Menu, MenuItem, Typography} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import {RestaurantContext} from "./RestaurantContext";
import {FirebaseAuthConsumer} from "@react-firebase/auth";

const ITEM_HEIGHT = 48;

const LunchMenu = (props) => {
    const [storedConfiguration, setStoredConfiguration] = React.useState(props.initialMenu);
    const [state, setState] = React.useState(props.initialMenu);
    
    const [addFormVisible, setAddFormVisible] = React.useState(false);
    
    useEffect(() => {
        if (storedConfiguration !== props.initialMenu) {
            setStoredConfiguration(props.initialMenu);
            setState(props.initialMenu);
            
            setAddFormVisible(false);
        }
    }, [props.initialMenu]);
    
    const setListAndUpdateBackend = newList => {
        const tempState = Object.assign({}, state);
        tempState.dishes = newList;
        setState(tempState);
        props.onUpdate(tempState);

    }
    
    const setLunchStartAndUpdateBackend = newLunchStart => {
        const tempState = Object.assign({}, state);
        tempState.openTime = newLunchStart;
        setState(tempState);
        props.onUpdate(tempState);
    }

    const setLunchEndAndUpdateBackend = newLunchEnd => {
        const tempState = Object.assign({}, state);
        tempState.closeTime = newLunchEnd;
        setState(tempState);
        props.onUpdate(tempState);
    }
    
    const handleSubmit = value => {
        if (value) {
            const newValue = Object.assign({},
                value,
                {id: uuidv4()},
            )

            setListAndUpdateBackend((state?.dishes ?? []).concat(newValue));
            setAddFormVisible(false);
        }
    };

    const handleUpdate = value => {
        const newList = (state?.dishes??[]).map(u => u.id !== value.id ? u : value);
        setListAndUpdateBackend(newList)
    }
    
    const handleDelete = key => {
        const newList = (state?.dishes??[]).filter(u => u.id !== key);
        setListAndUpdateBackend(newList);
    }

    const enabled = props.enabled;

    function handleLunchStartTimeChange(event) {
        setLunchStartAndUpdateBackend(event.target.value);
    }
    
    function handleLunchEndTimeChange(event) {
        setLunchEndAndUpdateBackend(event.target.value);
    }

    const handleMoveUp = key => {
        console.log("request to move up");
        const clonedDishes = [...state?.dishes];
        const index = clonedDishes.findIndex(el => el.id === key);
        clonedDishes.splice(index - 1, 0, clonedDishes.splice(index, 1)[0]);
        setListAndUpdateBackend(clonedDishes);
    }

    return (
        <>
            <Divider/>
            <Box mt={1}>
            <Grid container spacing={1} item>
                <Grid item>
                    <ListSubheader>Lunchmeny</ListSubheader>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} item >
                        <Grid item>
                            <Box my={1}>

                                <TextField
                                    disabled={! enabled}
                                    id="time1"
                                    label="Från"
                                    type="time"
                                    value={state?.openTime ?? ""}
                                    onChange={handleLunchStartTimeChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={1}>
                                <TextField
                                    disabled={! enabled}
                                    id="time2"
                                    label="Till"
                                    type="time"
                                    value={state?.closeTime ?? ""}
                                    onChange={handleLunchEndTimeChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            </Box>
            <List>
                {(state?.dishes??[]).map((item,index) => (
                    <DailyDishListItem item={item} key={item.id} onUpdate={handleUpdate} onDeleteItem={handleDelete} onMoveUp={index === 0 ? null : handleMoveUp}/>

                ))}
            </List>
            {!addFormVisible &&
            <Box mx={2} display={"flex"} justifyContent={"flex-end"}>
                <Button disabled={! enabled} variant={"contained"} onClick={() => setAddFormVisible(true)}>Lägg till maträtt</Button>
            </Box>
            }
            {addFormVisible && <Box m={2}><EditDish onSubmit={handleSubmit} onCancel={()=>setAddFormVisible(false)}/></Box>}
        </>
    );
};


function DayCard(props) {
    const [storedConfiguration, setStoredConfiguration] = React.useState(props.dayConfig)
    const [state, setState] = React.useState(props.dayConfig);
    
    const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    const openTimeValid = timeRegex.test(state?.openTime ?? "");
    
    useEffect(() => {
        if (storedConfiguration !== props.dayConfig) {
            setStoredConfiguration(props.dayConfig);
            setState(props.dayConfig);
        }
    }, [props.dayConfig]);

    function storeDayConfig(newOpenSettings) {
        firebase.auth().currentUser.getIdToken()
            .then(token => {
                const payload = {
                    'restaurantId' : props.restaurantId,
                    'isoDate' : props.date.format(moment.HTML5_FMT.DATE),
                    'dayConfig' : newOpenSettings}
                
                fetch('api/restaurant/storeDayConfig', {
                    method: 'POST', 
                    headers: {  'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`},
                    body: JSON.stringify(payload)
                })  
                    .then(response => {
                        if (!response.ok) {
                            alert("Request failed")
                        }
                    })
                    .catch(error => {
                        alert("Network error")
                    });
            });
    }

    function handleOpenChange(event) {
        const newOpenSettings = {isOpen: event.target.checked, openTime: state.openTime, closeTime: state.closeTime, dayMenu: state.dayMenu};
        setState(newOpenSettings);
        storeDayConfig(newOpenSettings);
    }

    function handleOpenTimeChange(event) {
        const newVar = {isOpen: state.isOpen, openTime: event.target.value, closeTime: state.closeTime, dayMenu: state.dayMenu};
        setState(newVar);
        storeDayConfig(newVar);
    }

    function handleCloseTimeChange(event) {
        const newVar = {isOpen: state.isOpen, openTime: state.openTime, closeTime: event.target.value, dayMenu: state.dayMenu};
        setState(newVar);
        storeDayConfig(newVar);
    }
    
    function handleMenuUpdated(newMenu)
    {
        const newVar = {isOpen: state.isOpen, openTime: state.openTime, closeTime: state.closeTime, 
            dayMenu: newMenu};
        setState(newVar);
        storeDayConfig(newVar);
    }
    

    function DayMenu() {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const selectCopyPreviousDay = () => {
            handleClose();
            props.onCopyDay();
        };

        return (
            <Hidden xsDown>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '30ch',
                        },
                    }}
                >
                    <MenuItem onClick={selectCopyPreviousDay}>Kopiera från förra dagen</MenuItem>
                </Menu>
            </Hidden>
        );
    }

    return <Grid item xs={12} md={6} lg={4}>
        <Card>
            <List>
                <Grid container>
                    <Grid container item xs={6} spacing={0}>
                        <Grid item>
                        <ListSubheader>{props.date.toDate().toLocaleDateString(undefined, {
                            weekday: 'long',
                            month: 'numeric',
                            day: 'numeric'  
                        })}
                        </ListSubheader>
                        </Grid>
                        <Grid item>
                            <DayMenu/>                             
                        </Grid>
                    </Grid>
                    
                    <Grid container item xs={6} justify={"flex-end"}>
                        <Grid item>
                            <Box m={2}>
                        <Grid container item direction={"column"} alignItems={"flex-start"}>
                        <Grid item> 
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={state?.isOpen ?? false} onChange={handleOpenChange}/>}
                                        label="Beställningar"
                                    />
                                </FormGroup>
                                
                            </FormControl>
                        </Grid>
                        <Grid container spacing={1} item>
                            <Grid item>
                                <TextField
                                    disabled={! (state?.isOpen ?? false)}
                                    id="time1"
                                    label="Från"
                                    type="time"
                                    value={state?.openTime ?? ""}
                                    error={(state?.isOpen ?? false) && ! openTimeValid}
                                    onChange={handleOpenTimeChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    disabled={! (state?.isOpen ?? false)}
                                    id="time2"
                                    label="Till"
                                    type="time"
                                    value={state?.closeTime ?? ""}
                                    onChange={handleCloseTimeChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid>

                        </Grid>
                        </Grid>
                            </Box>
                    </Grid>
                    </Grid>
                </Grid>
            </List>
            <Box mb={2} mt={1}>
                <LunchMenu date={props.date} enabled={state?.isOpen ?? false} initialMenu={state?.dayMenu} 
                           onUpdate={handleMenuUpdated} />
            </Box>
        </Card>
    </Grid>;
}

function DailySettingsSubview(props) {
    const initialRestaurantState = {
        loaded: false,
        name: '',
        weekdaySettings: [{defaultOpen: false,
        defaultOpenTime: "",
        defaultCloseTime: "",
        defaultLunchStartTime: "",
        defaultLunchEndTime: ""}]};

    const initialStateDayConfig = {
        loaded: false,
        dayConfigs: [
            // {
            //     isOpen: false,
            //     openTime: "",
            //     closeTime: "",
            //     dayMenu: {
            //         openTime: "",
            //         closeTime: "",
            //         dishes: [
            //             {
            //                 name: "Köttbullar",
            //                 description: "Kioskgjort mos ingår",
            //                 price: "90.50",
            //                 id: "123"
            //             }
            //         ]
            //     }
            // },
            null,
            null, null, null, null, null, null]
    };

    const [state, setState] = useState({
        restaurant: null, 
        dayConfig: initialStateDayConfig,
        loadingUser: true, 
        user: props.user});

    const [mondayCurrentWeek, setMoment] = useState(moment().subtract(moment().isoWeekday() - 1, 'days'));

    if (props.restaurant !== state.restaurant)
    {
        setState({
            restaurant: props.restaurant,
            loadingUser: false,
            user: state.user,
            dayConfig: state.dayConfig
        });

        loadRestaurant(props.user, mondayCurrentWeek, props.restaurant).then();
    }
    
   
    const [year, week, ] = [mondayCurrentWeek.year(), mondayCurrentWeek.isoWeek(), ];

    function handleNextWeek()
    {
        const moment2 = mondayCurrentWeek.clone().add(7, 'days');
        setMoment(moment2);
        loadWeek(props.user, state.restaurant, moment2).then();
    }
    
    function handlePreviousWeek()
    {
        const moment2 = mondayCurrentWeek.clone().subtract(7, 'days');
        setMoment(moment2);
        loadWeek(props.user, state.restaurant, moment2).then();
    }

    // function copyFromPreviousWeek()
    // {
    //     console.log("Copy from previous week");
    //    
    //     // Week to load from
    //     const moment2 = mondayCurrentWeek.clone().subtract(7, 'days');
    //    
    //     // Copy and load
    //     copyWeek(state.user, state.restaurant, mondayCurrentWeek, moment2).then();
    // }

    function copyFromPreviousDay(today)
    {
        console.log("Copy from previous day");

        // // Week to load from
        // const moment2 = mondayCurrentWeek.clone().subtract(7, 'days');
        //
        // Copy and load
        copyDay(state.user, state.restaurant, today, today.clone().subtract(1, 'days')).then();
    }



    function WeekMenu() {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        // const selectCopyPrevious = () => {
        //     handleClose();
        //     copyFromPreviousWeek();
        // };

        const selectCopyWeek = (mondayDate) => {
            handleClose();
            copyWeek(state.user, state.restaurant, mondayCurrentWeek, mondayDate).then();
        };
        
        const numberOfWeeksInList = 7;
        const previousWeeks = Array.from(Array(numberOfWeeksInList), (_, index) => mondayCurrentWeek.clone().subtract(7 * (index + 1), 'days'));

        return (
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '30ch',
                        },
                    }}
                >
                    {(previousWeeks).map(u => <MenuItem onClick={() => selectCopyWeek(u)}>Kopiera från v{u.isoWeek()}</MenuItem>)}
                </Menu>
            </div>
        );
    }


    function authenticatedView() {
        const tuesday = mondayCurrentWeek.clone().add(1, 'days');
        const wednesday = mondayCurrentWeek.clone().add(2, 'days');
        const thursday = mondayCurrentWeek.clone().add(3, 'days');
        const friday = mondayCurrentWeek.clone().add(4, 'days');
        const saturday = mondayCurrentWeek.clone().add(5, 'days');
        const sunday = mondayCurrentWeek.clone().add(6, 'days');
        return (
            <>
                <Box mt={4}>{
                    (state.loadingUser === true)
                    ? (<Typography variant={"h6"}>Loading...</Typography>)
                    : (<Typography variant={"h6"}>{state.restaurant?.name}</Typography>)}
                </Box>

                <Box my={2} >
                    <Grid container>
                    <Grid item xs={9} container alignItems="center">
                        <Grid item><IconButton onClick={handlePreviousWeek}><NavigateBeforeIcon/></IconButton></Grid>
                        <Grid item><TaMedIntermediateHeading>v{week}, {year}</TaMedIntermediateHeading></Grid>
                        <Grid item><IconButton onClick={handleNextWeek}><NavigateNextIcon/></IconButton></Grid>
                    </Grid>
                        <Grid item xs={3} container justify="flex-end">
                            <>
                                {/*<IconButton onClick={handleNextWeek}><MoreVertIcon/></IconButton>*/}
                                <WeekMenu/>
                            </>
                        </Grid>
                    </Grid>
                </Box>
                
                <Grid container spacing={2}>
                    <DayCard date={mondayCurrentWeek} defaults={state.restaurant?.weekdaySettings[0]} 
                             dayConfig={state.dayConfig.dayConfigs[0]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(mondayCurrentWeek)}}/>
                    <DayCard date={tuesday} 
                             defaults={state.restaurant?.weekdaySettings[1]}
                             dayConfig={state.dayConfig.dayConfigs[1]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(tuesday)}}/>
                    <DayCard date={wednesday} 
                             defaults={state.restaurant?.weekdaySettings[2]}
                             dayConfig={state.dayConfig.dayConfigs[2]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(wednesday)}}/>
                    <DayCard date={thursday} 
                             defaults={state.restaurant?.weekdaySettings[3]}
                             dayConfig={state.dayConfig.dayConfigs[3]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(thursday)}}/>
                    <DayCard date={friday} 
                             defaults={state.restaurant?.weekdaySettings[4]}
                             dayConfig={state.dayConfig.dayConfigs[4]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(friday)}}/>
                    <DayCard date={saturday} 
                             defaults={state.restaurant?.weekdaySettings[5]}
                             dayConfig={state.dayConfig.dayConfigs[5]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(saturday)}}/>
                    <DayCard date={sunday} 
                             defaults={state.restaurant?.weekdaySettings[6]}
                             dayConfig={state.dayConfig.dayConfigs[6]} restaurantId = {state.restaurant?.id} onCopyDay={() => {copyFromPreviousDay(sunday)}}/>
                </Grid>
            </>
        );
    }
    
    async function loadWeek(user, restaurant, mondayMoment) {
        const token = await user.getIdToken();
                const weekConfigResponse = await fetch('api/restaurant/getSevenDays?' + new URLSearchParams({
                    restaurantId: restaurant.id,
                    firstIsoDate: mondayMoment.format(moment.HTML5_FMT.DATE)
                })
                    , {
                        headers: {'Authorization': `Bearer ${token}`},
                    });

                const weekConfig = await weekConfigResponse.json();
                setState({
                    restaurant: restaurant,
                    loadingUser: false,
                    user: user,
                    dayConfig: {loaded: true, dayConfigs: weekConfig}
            });
    }

    async function copyWeek(user, restaurant, mondayMoment, copyFromMoment) {
        const token = await user.getIdToken();
        const weekConfigResponse = await fetch('api/restaurant/copyWeek?' + new URLSearchParams({
            restaurantId: restaurant.id,
            firstIsoDate: mondayMoment.format(moment.HTML5_FMT.DATE),
            copyFrom: copyFromMoment.format(moment.HTML5_FMT.DATE)
        })
            , {
                headers: {'Authorization': `Bearer ${token}`},
            });

        const weekConfig = await weekConfigResponse.json();
        setState({
            restaurant: restaurant,
            loadingUser: false,
            user: user,
            dayConfig: {loaded: true, dayConfigs: weekConfig}
        });
    }

    async function copyDay(user, restaurant, targetMoment, copyFromMoment) {
        const token = await user.getIdToken();
        const weekConfigResponse = await fetch('api/restaurant/copyDay?' + new URLSearchParams({
            restaurantId: restaurant.id,
            targetIsoDate: targetMoment.format(moment.HTML5_FMT.DATE),
            copyFrom: copyFromMoment.format(moment.HTML5_FMT.DATE)
        })
            , {
                headers: {'Authorization': `Bearer ${token}`},
            });

        const weekConfig = await weekConfigResponse.json();
        setState({
            restaurant: restaurant,
            loadingUser: false,
            user: user,
            dayConfig: {loaded: true, dayConfigs: weekConfig}
        });
    }



    async function loadRestaurant(user, mondayMoment, restaurant) {
        const data = restaurant;
        setState({restaurant: data, loadingUser: false, user: user, dayConfig: initialStateDayConfig});

        await loadWeek(user, data, mondayMoment);
    }
    
    function userUpdated(user) {
        setState({
            restaurant: initialRestaurantState,
            dayConfig: initialStateDayConfig,
            loadingUser: true, 
            user: user});
        
        // loadRestaurant(user, mondayCurrentWeek);

    }

    useEffect(() => {
        return firebase.auth().onAuthStateChanged(
            authUser => userUpdated(authUser)
        );
    }, []);

    return state.user !== null
        ? authenticatedView()
        : <div>Not authenticated</div>
}

export default function DailySettings()
{
    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            if (!isSignedIn)
                return <>not authenticated</>
            else
                return <RestaurantContext.Consumer>
                    {(selectedRestaurant) => (
                        selectedRestaurant ? <DailySettingsSubview user={user} restaurant={selectedRestaurant}/>
                            : <></>
                    )}

                </RestaurantContext.Consumer>
        }
        }
    </FirebaseAuthConsumer>
}