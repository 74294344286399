import React from 'react';
import {Menu, MenuItem, useMediaQuery} from "@material-ui/core"
import Grid from '@material-ui/core/Grid';
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Box from "@material-ui/core/Box";
import {grayTheme, greenTheme, theme} from "./helpers";

const ITEM_HEIGHT = 48;



// const theLinks = <div>
//     <Button component={Link} to="/admin/lunchmenus" color={"inherit"}>dagar</Button>
//     <Button component={Link} to="/admin/a-la-carte" color={"inherit"}>à la carte</Button>
//     <Button component={Link} to="/admin/restaurant" color={"inherit"}>inställningar</Button>
// </div>;


export default function NavMenuPublic(props) {


    // let dummyCategories;
    // if (props.restaurant) {
    //     dummyCategories = [{label: 'dagar', url: "/admin/lunchmenus/"},
    //         {label: 'à la carte', url: '/admin/a-la-carte'},
    //         {label: 'inställningar', url: '/admin/restaurant'}];
    // } else {
    //     dummyCategories = [];
    // }
    //

    const appbarStyle = {
        color: '#f3c0aa',
    };

    const isXs = useMediaQuery(grayTheme.breakpoints.down('xs'));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const userAgent = navigator.userAgent || navigator.vendor;
    const testedAndroid = /android/i.test(userAgent);
    const testedIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    
    return (
        <>

            <AppBar position="static" style={appbarStyle} elevation={0}>

                <Grid container justify={"space-between"}>
                    <Grid item>
                        <Box mt={5} mb={10} mx={isXs ? 3 : 4} display={"flex"} justifyContent={"center"}>
                            <a href='/' style={{display: 'inline-block', width: '88px'}}><img width={"100%"}
                                                                                              src={"/images/logo.svg"}
                                                                                              alt={"Ta med"}/></a>
                        </Box>

                    </Grid>
                    <Grid item>
                        <Box mt={isXs ? 4 : 5} mx={isXs ? 1 : 2}>
                            <IconButton
                                style={{visibility : open ? "hidden" : "visible"}}
                                color="inherit"
                                aria-label="Open drawer"
                                edge="start"
                                onClick={handleClick}>
                                <MenuIcon/>
                            </IconButton>

                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        backgroundColor: greenTheme.palette.background.default,
                                        color: greenTheme.palette.text.primary,
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '30ch',
                                    },
                                }}
                            >
                                {testedAndroid ? <MenuItem onClick={() => {window.location.href = 'https://play.google.com/store/apps/details?id=se.bitzeal.tamed';}}>Ladda ner appen</MenuItem> : []}
                                {testedIos ? <MenuItem onClick={() => {window.location.href = "https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494";}}>Ladda ner appen</MenuItem> : []}
                                <MenuItem onClick={() => {window.location.href = '/admin/lunchmenus/';}}>Administration</MenuItem>
                            </Menu>
                            
                        </Box>
                    </Grid>

                </Grid>
                {props.children}
            </AppBar>

           
        </>
    );
}
