import React from 'react'
import { Component } from 'react'
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import { Route } from 'react-router';


export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
                const { component: Component, ...rest } = this.props;
                
                return <Route {...rest}
                              render={(props) => {
                                  if (isSignedIn) {
                                      return <Component {...props} />
                                  } else {
                                      return <div><h1>You are logged out</h1><p>Please log in to view this page</p></div>
                                  }
                                  
                              }} /> 
            }}
        </FirebaseAuthConsumer>

        
        // return <div>Auth Route here</div>
        // const { ready, authenticated } = this.state;
        // var link = document.createElement("a");
        // link.href = this.props.path;
        // const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        // if (!ready) {
        //     return <div></div>;
        // } else {
        //     const { component: Component, ...rest } = this.props;
        //     return <Route {...rest}
        //         render={(props) => {
        //             if (authenticated) {
        //                 return <Component {...props} />
        //             } else {
        //                 return <Redirect to={redirectUrl} />
        //             }
        //         }} />
        // }
    }
}
