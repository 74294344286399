import React from "react";
import {MuiThemeProvider, useMediaQuery} from "@material-ui/core";
import {grayTheme} from "./components/helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import NavMenuPublic from "./components/NavMenuPublic";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {TaMedHeading} from "./components/ta-med-styles/TaMedStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export function SwishCallback() {
    const isMdUp = useMediaQuery(grayTheme.breakpoints.up('md'));

    return (
        <MuiThemeProvider theme={grayTheme}>
            <CssBaseline/>
            <Helmet>
                <title>Ta Med - Beställ Take Away enkelt och betala med Swish</title>
                <meta name="description"
                      content="Ta Med är en mobilapp där du enkelt beställer Take Away från restauranger i din närhet. Betala med swish i appen och hämta upp."/>
            </Helmet>

            <NavMenuPublic>
                <Container maxWidth={"lg"}>
                    <Box mb={8} mx={isMdUp ? 10 : 1}>
                        <TaMedHeading>
                            Swish slutförd
                        </TaMedHeading>
                    </Box>
                </Container>
            </NavMenuPublic>
            <Container maxWidth={"lg"}>
                <Box mt={4} mx={isMdUp ? 10 : 1}>


                    <Typography variant={"h4"}>Gå tillbaka till appen. Om betalningen lyckades hittar du din beställning under fliken <b>Ordrar</b>.</Typography>
                    
                    <Box mt={10}>
                        <Grid container>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </MuiThemeProvider>
    );
}