import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {onRestaurantUpdated, RestaurantContext} from "./RestaurantContext";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {TaMedBody, TaMedHeading, TaMedOverline} from "./ta-med-styles/TaMedStyles";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import moment from "moment";
import {Star} from "@material-ui/icons";
import firebase from "firebase";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 200,
    },
}));


const SalesSummary = props => {
    const [restaurantId, setRestaurantId] = useState(props.selectedRestaurant.id);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    
    const [report, setReport] = useState(undefined);
    
    async function loadReport() {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch('api/admin/getSalesReport?' + new URLSearchParams({
            restaurantId: props.selectedRestaurant.id,
            startIsoDate: props.startDate,
            endIsoDate: props.endDate
        })
            , {
                headers: {'Authorization': `Bearer ${token}`},
            });

        const report = await response.json();

        setReport(report);
        console.log(report);
        
    }

    if (report === undefined)
    {
        setReport(null);
        loadReport().then();
    }

    if (restaurantId !== props.selectedRestaurant.id)
    {
        setRestaurantId(props.selectedRestaurant.id);
        setReport(null);
        loadReport().then();
    }
    
    if (startDate !== props.startDate)
    {
        setStartDate(props.startDate);
        setReport(null);
        loadReport().then();
    }
    
    if (endDate !== props.endDate)
    {
        setEndDate(props.endDate);
        setReport(null);
        loadReport().then();
    }
    
    return report ? <>
        <Box mt={4}>
        <Grid container>
            <Grid item xs={7} sm={4} md={3}>
                <TaMedBody>
                    Total försäljning:
                </TaMedBody>
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
                <TaMedBody>{report.totalAmountSales.toFixed(2)}&nbsp;kr</TaMedBody>
            </Grid>
            <Grid item xs={0} sm={4} md={6}/>

            <Grid item xs={7} sm={4} md={3}>
                <TaMedBody>
                    Antal order:
                </TaMedBody>
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
                <TaMedBody>{report.orderCount}</TaMedBody>
            </Grid>
            <Grid item xs={0} sm={4} md={6}/>   
            
            <Grid item xs={7} sm={4} md={3}>
            <TaMedBody>
                Genomsnittlig order:
            </TaMedBody>
        </Grid>
            <Grid item xs={5} sm={4} md={3}>
                <TaMedBody>{report.averageSale.toFixed(2)}&nbsp;kr</TaMedBody>
            </Grid>
            <Grid item xs={0} sm={4} md={6}/>
        </Grid>
        </Box>
        <Box mt={4}>
            <Typography variant={"h6"}>
                Rätter som sålt bäst
            </Typography>
        </Box>
        <Box mt={4}>
            <Grid container>
            {(report.topSellers ?? []).map(item => (
                <>
                    <Grid item xs={1} sm={1} md={1}>
                        <TaMedBody>{item.count}</TaMedBody>
                    </Grid>
                <Grid item xs={8} sm={4} md={3}>
                    <TaMedBody>{item.name}</TaMedBody>
                </Grid>
                    <Grid item xs={3} sm={4} md={3}>
                        <TaMedBody>{item.amount}&nbsp;kr</TaMedBody>
                    </Grid>
                    <Grid item xs={0} sm={3} md={5}/>
                </>
            ))}
            </Grid>
        </Box>
        <Box pb={4}/>
    </>:<></>
}

const DateRangeSelector = props => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(moment().format(moment.HTML5_FMT.DATE));
    const [toDate, setToDate] = useState(moment().format(moment.HTML5_FMT.DATE));

    function handleFromDateChange(event) {
        setFromDate(event.target.value);
        // setLunchStartAndUpdateBackend(event.target.value);
    }

    function handleToDateChange(event) {
        setToDate(event.target.value);
        // setLunchStartAndUpdateBackend(event.target.value);
    }

    return <> 
    <Grid container>
        <Grid item xs={12} sm={4}>
            <Box mt={1}>
            <TextField
                id="fromDate"
                label="Från"
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            </Box>
        </Grid>
        
        <Grid item xs={12} sm={4}>
            <Box mt={1}>
            <TextField
                id="toDate"
                label="Till"
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            </Box>
        </Grid>
        <Hidden xsDown>
            <Grid item sm={4}/>
        </Hidden>
    </Grid>
        {props.children(fromDate, toDate)}
    </>
};

const ReportContentView = (props) => 
{
  
    return (
        <>
            <Box mt={4}>
                <Typography variant={"h6"}>
                    Försäljningsrapport
                </Typography>
            </Box>

            <Box pt={4}>
                <DateRangeSelector>
                    {(startDate, endDate) => (<Box mt={2}>
                            
                            <RestaurantContext.Consumer>
                            {(selectedRestaurant) => (
                                selectedRestaurant ? <SalesSummary startDate={startDate} endDate={endDate} selectedRestaurant={selectedRestaurant}/>
                                    : <>No restaurant</>
                            )}

                        </RestaurantContext.Consumer>
                        
                    </Box>
                    )}
                </DateRangeSelector>
            </Box>
        </>
    );
}

const AuthenticatedView = (props) =>
{

    if (! props.restaurant) {
        return <LoadingBadge/>
    }

    return <>
    
        
        <ReportContentView restaurant={props.restaurant} onRestaurantUpdated={onRestaurantUpdated}/>
    </>
}

const LoadingBadge = () =>
{
    return <><Box mt={4}>Laddar...</Box></>
}


const NotAuthenticated = (props) => {
    return <>
        <Box mt={4}>
            <div><TaMedHeading>Du är inte inloggad</TaMedHeading></div>
        </Box>
    </>;
}

export function Report() {
    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            if (!isSignedIn)
                return <NotAuthenticated/>
            else
                return <RestaurantContext.Consumer>
                    {(selectedRestaurant) => (
                        selectedRestaurant ? <AuthenticatedView user={user} restaurant={selectedRestaurant}/>
                            : <>No restaurant</>
                    )}

                </RestaurantContext.Consumer>
        }
        }
    </FirebaseAuthConsumer>
}