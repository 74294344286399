import React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core/styles";
import {MenuItem} from "@material-ui/core";
import {grayTheme, greenTheme, theme} from "../helpers";


export function TaMedHeading(props)
{
    return (
        <Typography variant={"h2"}>
            {props.children}
        </Typography>
    );
}

export function TaMedIntermediateHeading(props)
{
    return (
        <Typography variant={"h6"}>
            {props.children}
        </Typography>
    );
}

export function TaMedBody(props)
{
    return (<Typography variant={"body1"} paragraph={true}>
        {props.children}
    </Typography>);
}

export function TaMedOverline(props)
{
    return (<Typography variant={"body1"} paragraph={true} style={{
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 500, letterSpacing: "1.5px", textTransform: "uppercase"}}>
        {props.children}
    </Typography>);
}
// font-family: Poppins;
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 16px;
// /* identical to box height, or 114% */
//
// letter-spacing: 1.5px;
// text-transform: uppercase;
export function TaMedCardHeading(props)
{
    return (<Box mb={2}><Typography variant={"h5"} >
        {props.children}
    </Typography></Box>);
}

// export const MyMenuItem = withStyles({
//     root: {
//         color: greenTheme.palette.text.primary,
//        
//         // '&:hover': {
//         //     color: "white",
//         //    // backgroundColor: "#1fffff10",
//         // }
//     }
// })(MenuItem)

