import {createMuiTheme} from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import {Card} from "@material-ui/core";

export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
);

export const greenTheme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins',
    },
    palette: {
        type: 'dark',

        text: {
            primary: "#f3c0aa",
            secondary: "#000000"
        },
        
        primary: {
            main: '#2e543e',
        },
        secondary: {
            main: '#3ea669',
        },
        background: {
            default: '#2e543e',
            paper: {main:'#e1dcd7'}
        }

    },
});

export const grayTheme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins',
    },
    palette: {
        type: "light",

        primary: {
            main: '#2e543e',
        },
        secondary: {
            main: '#3ea669',
        },
        background: {
            paper: '#2e543e',
            default: '#e1dcd7'
        }

    },
});


greenTheme.typography.h2 = {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '56px',
    [greenTheme.breakpoints.up('md')]: {
        fontSize: '60px',
        lineHeight: '72px',
        fontWeight: 500
    },
};

greenTheme.typography.h4 = {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    [greenTheme.breakpoints.up('md')]: {
        fontSize: '34px',
        lineHeight: '42px',
        fontWeight: 500
    },
};

export const theme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins'
    },
    palette: {
        primary: {
            main: '#2e543e',
        },
        secondary: {
            main: '#3ea669',
        },
        background: {
            paper: '#e1dcd7'
        }

    },
});

theme.typography.h2 = greenTheme.typography.h2 ;
theme.typography.h4 = greenTheme.typography.h4 ;
grayTheme.typography.h2 = greenTheme.typography.h2 ;
grayTheme.typography.h4 = greenTheme.typography.h4 ;


