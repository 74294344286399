import React, {useState} from 'react';
import {Button, Card, MuiThemeProvider, useMediaQuery} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {TaMedBody, TaMedHeading, TaMedIntermediateHeading, TaMedOverline} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {grayTheme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import List from "@material-ui/core/List";
import * as PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";
import NavMenuPublic from "./NavMenuPublic";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import {DownloadButtons} from "./DownloadButtons";


function DishEntry(props) {
    console.log(props.item);

    return <Grid container spacing={3}>
        <Grid item xs={10}>
            <ListItemText primary={props.item.name} secondary={props.item.description}/>
        </Grid>
        <Grid item xs={2}>
            <Box py={2}>
                <div style={{float: "right"}}>
                    <TaMedBody>{props.item.price}&nbsp;kr</TaMedBody>
                </div>
                </Box>
        </Grid>
    </Grid>

}

DishEntry.propTypes = {item: PropTypes.any};

function GreenCard(props) {
    const greenCardStyle = {
        color: '#f3c0aa',
        borderRadius: '16px'
    };
    
    return <Card style={greenCardStyle}>
        {props.children}
    </Card>;
}

GreenCard.propTypes = {children: PropTypes.node};

function TodaysLunch(props) {
    if ((!props.currentLunchMenu) && (!props.futureLunchMenu)) {
        return <></>;
    }
    
    const lunchmenu = props.currentLunchMenu ? props.currentLunchMenu : props.futureLunchMenu;
    
    if (lunchmenu.length === 0)
        return <></>;

    return <>
        <TaMedOverline>
            Dagens lunch, {moment().toDate().toLocaleDateString(undefined, {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric'
        })} 
        </TaMedOverline>
        <Grid container>
            <Grid item xs={12}>
                <List>
                    {(lunchmenu ?? []).map(item => (
                        <DishEntry item={item} key={item}/>
                    ))}
                </List>
            </Grid>
        </Grid>

    </>
}

function ALaCarteMenu(props) {
    if (!props.restaurant.aLaCarteMenu)
        return <></>;

    if (props.restaurant.aLaCarteMenu.length === 0)
        return <></>;


    const renderHeading = props.restaurant.currentLunchMenu && props.restaurant.currentLunchMenu.length > 0;
    return <>
        {renderHeading ? <TaMedOverline>Stående alternativ</TaMedOverline> : <TaMedOverline>Take Away Meny</TaMedOverline>}
        <Grid container>
            <Grid item xs={12}>
                <List>
                    {(props.restaurant.aLaCarteMenu ?? []).map(item => (
                        <DishEntry item={item} key={item}/>
                    ))}
                </List>
            </Grid>
        </Grid>

    </>
}

function OpeningHours(props) {

    if (!props.restaurant.openFrom)
        return <TaMedBody>
            Stängt idag
        </TaMedBody>

    return <TaMedBody>
        <i style={{fontSize: '20px', verticalAlign: 'middle'}} className="material-icons">access_time</i>
        &nbsp;
        <span style={{verticalAlign: 'middle'}}>
        {props.restaurant.openFrom}-{props.restaurant.openTo}
    </span>
    </TaMedBody>
}
function DelivryTime(props) {

    return <TaMedBody>
        <img style={{verticalAlign: 'middle'}} width={'20px'} src={"/images/lunchbag.svg"} alt={"Take away tid"}/>
        &nbsp;
        <span style={{verticalAlign: 'middle'}}>
        {props.restaurant.expectedWaitTimeMinutes}&nbsp;min
    </span>
    </TaMedBody>
}


const MenuContentView = (props) => {

    const isMdUp = useMediaQuery(grayTheme.breakpoints.up('md'));

    return <>
        <Helmet>
            <title>{props.restaurant.name} | Take Away Meny</title>
            <meta name="description"
                  content={"Beställ Take Away från " + props.restaurant.name + " - " + props.restaurant.webSubtitle + ". Dagens meny för take away."}/>
        </Helmet>

        <NavMenuPublic>
            <Container maxWidth={"md"}>
            <Box mb={8} mx={isMdUp ? 10 : 1}>
                {/*<TaMedIntermediateHeading>{props.restaurant.webSubtitle}</TaMedIntermediateHeading>*/}
                <Typography variant={"body1"}>{props.restaurant.webSubtitle}</Typography>
                <TaMedHeading>
                    {props.restaurant.name}
                </TaMedHeading>

                <Grid container>
                    <Grid item>
                        <Box mt={1} mr={3}>
                            <DelivryTime restaurant={props.restaurant}/>
                        </Box>
                    </Grid>
                    
                    <Grid item>
                        
                <Box mt={1} mr={3}>
                    <OpeningHours restaurant={props.restaurant}/>
                </Box>
                    </Grid>
                    
                </Grid>
            </Box>
            </Container>
        </NavMenuPublic>
        <Container maxWidth={"md"}>
            <Box mx={isMdUp ? 10 : 1}>
            <Grid item xs={12}>
                <Box mt={8}>
                    {/*<TaMedIntermediateHeading>{props.restaurant.webSubtitle}</TaMedIntermediateHeading>*/}
                    <TaMedIntermediateHeading>{props.restaurant.description}</TaMedIntermediateHeading>
                </Box>
            </Grid>
                <Box mt={8}><TodaysLunch currentLunchMenu={props.restaurant.currentLunchMenu} futureLunchMenu={props.restaurant.futureLunchMenu}/></Box>
                <Box mt={8}>
            <ALaCarteMenu restaurant={props.restaurant}/>
                </Box>
         

            <Box mt={4}><GreenCard>
                <Grid container spacing={0}
                      direction="row"
                      alignItems="center"
                      justify="center">
                    <Grid item>
                        <Box mt={4} p={2}>
                            <Typography variant={"h4"} style={{textAlign: 'center'}}>
                                <Hidden xsDown>
                                Ladda ner vår app för att<br />köpa take away
                                </Hidden>
                                <Hidden smUp>
                                    Ladda ner vår app för att köpa take away
                                </Hidden>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box m={2}><DownloadButtons restaurant={props.restaurant}/></Box>
            </GreenCard>
            </Box>


            <Box mt={10} mb={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box m={2} display={"flex"} justifyContent={"center"}>
                            <Button component={Link} to={"/"}>Startsida</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box m={2} display={"flex"} justifyContent={"center"}>
                            <Button component={Link} to={"/restauranger"}>Restauranger</Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
            </Box>
        </Container>
    </>
}

const LoadingBadge = () => {
    return <></>
}

const DynamicView = (props) => {
    const [restaurant, setRestaurant] = useState(undefined);

    const reloadrestaurant = async () => {
        const restaurantTask = fetch('api/webmenu/restaurantdetails?slug=' + props.slug, {
            // headers: {'Authorization': `Bearer ${token}`}
        });

        const data = await (await restaurantTask).json();
        console.log(data);
        setRestaurant(data);
    }

    if (restaurant === undefined) {
        setRestaurant(null);

        // Reload restaurant
        reloadrestaurant().then();
    }

    if (!restaurant) {
        return <LoadingBadge/>
    }

    return <>


        <MenuContentView restaurant={restaurant}/>
    </>
}


export default function TakeAwayMenu(props) {
    const restaurantslug = props.match.params.restaurantslug;
    console.log(restaurantslug);
    return <MuiThemeProvider theme={grayTheme}>
        <CssBaseline/>
        <DynamicView slug={restaurantslug}/>
    </MuiThemeProvider>

}
