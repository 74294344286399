import React, {useState} from 'react';
import {MuiThemeProvider, useMediaQuery} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {TaMedHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {grayTheme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import TextField from "@material-ui/core/TextField";
import * as PropTypes from "prop-types";
import NavMenuPublic from "./NavMenuPublic";
import makeStyles from "@material-ui/core/styles/makeStyles";

const textLinkStyle = makeStyles({
    root: { color: 'inherit', textDecoration: 'inherit'}, // a style rule
});

function RestaurantListEntry(props) {
    const classes = textLinkStyle();
    
    return <Box my={4}><Typography variant={"h5"}> 
        <Link  to={'/take-away-meny/' + props.item.slug} className={classes.root}>
        {props.item.name}
    </Link>
    </Typography>
    </Box>
    
}

RestaurantListEntry.propTypes = {item: PropTypes.any};

const RestaurantList = (props) =>
{
    
    return <Box mt={3}>
            {(props.restaurants.restaurantList ?? []).map(item => (
                <RestaurantListEntry item={item} key={item.slug}/>
            ))}
    </Box>
}

const LoadingBadge = () =>
{
    return <><Box mt={4}>Laddar...</Box></>
}

const DynamicView = (props) => {
    const [restaurant, setRestaurant] = useState(undefined);

    const reloadrestaurant = async () => {
        const restaurantTask = fetch('api/webmenu/getall', {
            // headers: {'Authorization': `Bearer ${token}`}
        });

        const data = await (await restaurantTask).json();
        console.log(data);
        setRestaurant({
            restaurantList: data
        });
    }
    
    if (restaurant === undefined) {
        setRestaurant(null);

        // Reload restaurant
        reloadrestaurant().then();
    }

    if (!restaurant) {
        return <LoadingBadge/>
    }

    return <>
        <RestaurantList restaurants={restaurant}/>
    </>
}


export default function Restaurants() {
    const isMdUp = useMediaQuery(grayTheme.breakpoints.up('md'));

        return (
            <MuiThemeProvider theme={grayTheme}>
                <CssBaseline/>
                <Helmet>
                    <title>Ta Med - Beställ Take Away enkelt och betala med Swish</title>
                    <meta name="description" content="Ta Med är en mobilapp där du enkelt beställer Take Away från restauranger i din närhet. Betala med swish i appen och hämta upp." />
                </Helmet>

                <NavMenuPublic>
                    <Container maxWidth={"md"}>
                        <Box mb={8} mx={isMdUp ? 10 : 1}>
                            <TaMedHeading>
                                Take away restauranger
                            </TaMedHeading>

                        </Box>
                    </Container>
                </NavMenuPublic>
                <Container maxWidth={"md"}>
                    <Box mx={isMdUp ? 10 : 1}>


                    <DynamicView/>
                    
                    <Box mt={10}>
                        <Grid container>
                            
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </MuiThemeProvider>
        );
}
