import React from 'react'
import { Component } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css';
import {firebaseConfig} from "../../firebaseConfig";
import Button from "@material-ui/core/Button";

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true; // Don't redirect
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            // document.getElementById('loader').style.display = 'none';
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/admin/lunchmenus/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
        },
        {
            provider: "apple.com",
           
        },
    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    // Privacy policy url.
    privacyPolicyUrl: '/privacy'
};

firebase.initializeApp(firebaseConfig);
    const ui = new firebaseui.auth.AuthUI(firebase.auth());

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
            return <FirebaseAuthConsumer>
                {({isSignedIn, user, providerId}) => {
                    window.setTimeout(() => {
                        this.checkUi();
                    });
                    if (isSignedIn) return <Button
                        onClick={() => {
                            // const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                            firebase.auth().signOut();
                        }}
                    >Sign Out
                    </Button>
                    else {
                        return <div><h1>Signed out</h1><div id="firebaseui-auth-container"></div></div>
                    }
                }
                }
        </FirebaseAuthConsumer>
            
    }
    
    checkUi()
    {
        console.log("checkUi running");
        ui.reset();
        
        var user = firebase.auth().currentUser;
        var name, email, photoUrl, uid, emailVerified;

        if (user != null) {
            name = user.displayName;
            email = user.email;
            photoUrl = user.photoURL;
            emailVerified = user.emailVerified;
            uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                             // this value to authenticate with your backend server, if
                             // you have one. Use User.getToken() instead.
            console.log("user logged in: " + name)
        }
        else
        {
            console.log("starting ui")
            ui.start('#firebaseui-auth-container', uiConfig);
        }
    
    }

}
