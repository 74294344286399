import React, {Component, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button, InputLabel, MenuItem, Select} from "@material-ui/core"
import Grid from '@material-ui/core/Grid';
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { LoginMenu } from './api-authorization/LoginMenu';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import FormControl from "@material-ui/core/FormControl";
import {grayTheme, greenTheme, theme} from "./helpers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";


// const theLinks = <div>
//     <Button component={Link} to="/admin/lunchmenus" color={"inherit"}>dagar</Button>
//     <Button component={Link} to="/admin/a-la-carte" color={"inherit"}>à la carte</Button>
//     <Button component={Link} to="/admin/restaurant" color={"inherit"}>inställningar</Button>
// </div>;

    
export default function NavMenuAdmin(props) {
    // const displayName = NavMenu.name;

    const [restaurants, setRestaurants] = useState(props.restaurants);
    const [selectedRestaurantId, setSelectedRestaurantId] = React.useState("");

    if (props.restaurant && props.restaurant.id !== selectedRestaurantId)
    {
        setSelectedRestaurantId(props.restaurant.id);
    }
    
    if (props.restaurants !== restaurants) {
        setRestaurants(props.restaurants);
        if (props.restaurants.length > 0)
        {
            setSelectedRestaurantId(props.restaurants[0].id);
        }
    }
    
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    let dummyCategories;
    if (props.restaurant)
    {
        dummyCategories = [{label: 'dagar', url:"/admin/lunchmenus/"},
            {label: 'à la carte', url: '/admin/a-la-carte'},
            {label: 'rapport', url: '/admin/report'},
            {label: 'avbryt order', url: '/admin/refund'},
            {label: 'inställningar', url: '/admin/restaurant'}];
    }
    else
    {
        dummyCategories = [];
    }
    

    const drawer = (
        <div>
            <List>
                {dummyCategories.map((text, index) => (
                    <ListItem button key={text.label} component={NavLink} to={text.url} activeClassName={'Mui-selected'} onClick={handleDrawerToggle}>
                        <ListItemText primary={text.label}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const theLinks = <div>
            {dummyCategories.map((entry, index) => (
                <Button key={entry.url} component={NavLink} to={entry.url} color={"inherit"}  activeStyle={{ fontWeight: 'bold' }} style={{paddingRight: '16px', paddingLeft: '16px'}}>{entry.label}</Button>
            ))}
        </div>;
    
    const menuItems = props.restaurants ? props.restaurants.map((entry) => (
        <MenuItem value={entry.id} key={entry.id}>{entry.name}</MenuItem>
    )) : [];
    
    

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 220,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        inputlabel: {
          color: '#ffffffaa'  
        },
        select: {
            color: 'white',
            '&:before': {
                borderColor: '#ffffffaa',
            },
            '&:hover:not(.Mui-disabled):before': {
                borderColor: '#ffffffaa',
            }
        },
        icon: {
            fill: '#ffffffaa',
        },
    }));
    
    const classes = useStyles(theme);
    
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Hidden lgUp><IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}>
                        <MenuIcon/>
                    </IconButton>
                    </Hidden>
                   
                    <Grid container spacing={0} alignItems={"center"}>
                        {/*<Grid item xs={2}>*/}
                        {/*    <Button component={NavLink} to="/" noWrap*/}
                        {/*            color="inherit">tamed.se</Button>*/}
                        {/*</Grid>*/}
                        <Grid item xs={5}>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel className={classes.inputlabel}
                                                id="demo-simple-select-label">Restaurang</InputLabel>
                                    <Select
                                        className={classes.select}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedRestaurantId}
                                        onChange={(value) => {
                                            props.onRestaurantChanged(value.target.value)
                                        }}
                                        inputProps={{
                                            classes: {
                                                icon: classes.icon,
                                            },
                                        }}
                                    >
                                        {menuItems}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Hidden mdDown>
                            
                            <Grid item xs={5}>
                                {theLinks}
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={5}/>
                        </Hidden>
                        <Grid item xs={2}>
                            <div style={{float: "right"}}>
                                <LoginMenu/>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <nav>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden lgUp>
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <IconButton onClick={handleDrawerToggle}>
                            <CloseIcon/>
                        </IconButton>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </>
    );
}
