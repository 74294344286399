import React, {Component} from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import {Route} from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import {Login} from './components/api-authorization/Login';
import CssBaseline from '@material-ui/core/CssBaseline';

import firebase from "firebase/app";
import "firebase/auth";

import {FirebaseAuthProvider} from "@react-firebase/auth";

import {firebaseConfig} from './firebaseConfig.js'
import './custom.css'
import Restaurant from "./components/Restaurant"
import DailySettings from "./components/DailySettings";
import {TaMedHeading} from "./components/ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {theme} from "./components/helpers";
import ALaCarte from "./components/ALaCarte";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Restaurants from "./components/Restaurants";
import TakeAwayMenu from "./components/TakeAwayMenu";
import Banners from "./components/Banners";
import SysOp from "./components/SysOp";
import {RestaurantContext} from "./components/RestaurantContext";
import {SwishCallback} from "./SwishCallback";
import {Report} from "./components/Report";
import {Refund} from "./components/Refund";


function AdminHome() {
    return (
        <RestaurantContext.Consumer>
            {(selectedRestaurant) => (
                selectedRestaurant ?
                <Box my={4}>
                    <TaMedHeading>Admin {selectedRestaurant.name}</TaMedHeading>
                </Box>        
                    : <></>
            )}
        
        </RestaurantContext.Consumer>
    );
}

function Admin() {
            return <Layout>
                <AuthorizeRoute exact path='/admin' component={AdminHome}/>
                <AuthorizeRoute path='/admin/lunchmenus' component={DailySettings}/>
                <AuthorizeRoute path='/admin/a-la-carte' component={ALaCarte}/>
                <AuthorizeRoute path='/admin/report' component={Report}/>
                <AuthorizeRoute path='/admin/refund' component={Refund}/>
                <AuthorizeRoute path='/admin/restaurant' component={Restaurant}/>
                <Route path="/admin/account" component={Login}/>
            </Layout>
}


export default class App extends Component {
    static displayName = App.name;

    render() {

        return (
            <React.Fragment>
                <CssBaseline/>

                <MuiThemeProvider theme={theme}>
                    <ConfirmProvider defaultOptions={{
                        confirmationButtonProps: { autoFocus: true },
                        cancellationText: 'Avbryt',
                        confirmationText: 'Ok',
                        title: 'Är du säker?'
                    }}>
                    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
                        <Route exact path='/' component={Home}/>
                        <Route path='/restauranger' component={Restaurants}/>
                        <Route path='/banners' component={Banners}/>
                        <Route exact path='/take-away-meny/:restaurantslug' component={TakeAwayMenu}/>
                        <Route path='/admin' component={Admin}/>
                        <Route path='/sysop' component={SysOp}/>
                        <Route path='/app-callback/swish' component={SwishCallback}/>
                        <Route path='/privacy' component={PrivacyPolicy}/>

                    </FirebaseAuthProvider>
                    </ConfirmProvider>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}
