import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import React from "react";

export function DownloadButtons(props) {
    let downloadString;
    
    if (! props.restaurant)
    {
        downloadString = 'Ladda ned Ta Med appen';
    }
    else
    {
        downloadString = 'Ladda ned ' + props.restaurant.name + ' take away app';
    }
    
    return <>
        <Grid container spacing={0}
              direction="row"
              alignItems="center"
              justify="center">
            <Grid item>
                <Box>
                    <a href='https://play.google.com/store/apps/details?id=se.bitzeal.tamed&hl=sv&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img alt={downloadString}
                             style={{height: '60px'}}
                             src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/></a>
                </Box>
            </Grid>
            <Grid item>
                <Box m={2}><a href='https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494'><img
                    alt={downloadString} style={{height: '40px'}}
                    src='images/download_on_app_store.png'/></a>
                </Box>
            </Grid>
        </Grid>
    </>
}