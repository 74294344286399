import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {onRestaurantUpdated, RestaurantContext} from "./RestaurantContext";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {TaMedBody, TaMedHeading} from "./ta-med-styles/TaMedStyles";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import firebase from "firebase";
import Button from "@material-ui/core/Button";
import {useConfirm} from "material-ui-confirm";
import * as PropTypes from "prop-types";
import {Dialog, DialogTitle} from "@material-ui/core";

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <Box px={3} pb={1}>
            <TaMedBody>{props.text}</TaMedBody>
            </Box>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const TransactionList = props => {
    const [restaurantId, setRestaurantId] = useState(props.selectedRestaurant.id);
    const [report, setReport] = useState(undefined);
    const [refundModalVisible, setRefundModalVisible] = useState(false);

    const confirm = useConfirm();

    const performRefund = async (orderId) => {
        const token = await firebase.auth().currentUser.getIdToken();

        fetch('api/admin/refundOrder?orderId=' + orderId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            // body: JSON.stringify(payload)
        })
            .then(response => {
                if (!response.ok) {
                    alert("Request failed")
                }
            })
            .catch(error => {
                alert("Network error")
            });
    };

        async function loadReport() {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch('api/admin/getRecentTransactions?' + new URLSearchParams({
            restaurantId: props.selectedRestaurant.id,
        })
            , {
                headers: {'Authorization': `Bearer ${token}`},
            });

        const report = await response.json();

        setReport(report);
        console.log(report);

    }

    if (report === undefined) {
        setReport(null);
        loadReport().then();
    }

    if (restaurantId !== props.selectedRestaurant.id) {
        setRestaurantId(props.selectedRestaurant.id);
        setReport(null);
        loadReport().then();
    }

    return report ? <>
        <SimpleDialog onClose={() => console.log("closing")} open={refundModalVisible} text={"Genomför återbetalning..."} title={"Vänta"}/>
        
        <Box mt={4}>
            <Grid container>
                {(report ?? []).map(item => <>
                    <Grid item xs={4} md={2}>
                        <Box mt={1}>
                            <TaMedHeading>{item.ticketId}</TaMedHeading>
                        </Box>
                    </Grid>
                    <Grid item xs={8} md={2}>
                        <Box mt={2}>
                            <Grid container direction={"column"} spacing={0}>
                                <Grid item>
                                    <Typography
                                        variant={"body1"}>{moment(item.dateLocal).format("HH:mm")}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"body1"}>{item.userFirstName}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"body1"}>{item.contactInfo}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Box mt={2}>
                            <Typography variant={"body1"}>{item.ticketText}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} md={1}>
                        <Box mt={2}>
                            <Typography variant={"body1"}>{item.totalAmount} kr</Typography>
                        </Box>
                    </Grid><Grid item xs={3} md={1}>
                    <Box mt={2}>
                        <Button variant={"contained"} onClick={() => {

                            confirm({ description: 'Vill du återbetala order ' + item.ticketId + '?' })
                                .then(async () => { 
                                    console.log("Refund");
                                    setRefundModalVisible(true);
                                    await performRefund(item.orderId);
                                    await sleep(2000);
                                    await loadReport();
                                    setRefundModalVisible(false);
                                })
                                .catch(() => { console.log("Don't refund") });
                        }}>Refund</Button>
                    </Box>
                </Grid>
                    <Grid item xs={12}>
                        <Box mt={4}/>
                    </Grid>
                </>)}

            </Grid>

            {/*    <Grid container>*/}
            {/*    {(report.topSellers ?? []).map(item => (*/}
            {/*        <>*/}
            {/*            <Grid item xs={1} sm={1} md={1}>*/}
            {/*                <TaMedBody>{item.count}</TaMedBody>*/}
            {/*            </Grid>*/}
            {/*        <Grid item xs={8} sm={4} md={3}>*/}
            {/*            <TaMedBody>{item.name}</TaMedBody>*/}
            {/*        </Grid>*/}
            {/*            <Grid item xs={3} sm={4} md={3}>*/}
            {/*                <TaMedBody>{item.amount}&nbsp;kr</TaMedBody>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={0} sm={3} md={5}/>*/}
            {/*        </>*/}
            {/*    ))}*/}
            {/*    </Grid>*/}
        </Box>
        <Box pb={4}/>
    </> : <></>
}


const RefundContentView = (props) => {
    return (
        <>
            <Box mt={4}>
                <Typography variant={"h6"}>
                    Senaste ordrar
                </Typography>
            </Box>

            <Box pt={4}>
                <TransactionList selectedRestaurant={props.restaurant}/>
            </Box>
        </>
    );
}

const AuthenticatedView = (props) => {

    if (!props.restaurant) {
        return <LoadingBadge/>
    }

    return <>


        <RefundContentView restaurant={props.restaurant} onRestaurantUpdated={onRestaurantUpdated}/>
    </>
}

const LoadingBadge = () => {
    return <><Box mt={4}>Laddar...</Box></>
}


const NotAuthenticated = (props) => {
    return <>
        <Box mt={4}>
            <div><TaMedHeading>Du är inte inloggad</TaMedHeading></div>
        </Box>
    </>;
}

export function Refund() {
    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            if (!isSignedIn)
                return <NotAuthenticated/>
            else
                return <RestaurantContext.Consumer>
                    {(selectedRestaurant) => (
                        selectedRestaurant ? <AuthenticatedView user={user} restaurant={selectedRestaurant}/>
                            : <>No restaurant</>
                    )}

                </RestaurantContext.Consumer>
        }
        }
    </FirebaseAuthConsumer>
}