import React from 'react';
import {MuiThemeProvider, useMediaQuery} from '@material-ui/core';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {TaMedBody, TaMedHeading, TaMedIntermediateHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {grayTheme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import * as PropTypes from "prop-types";
import NavMenuPublic from "./NavMenuPublic";


function CodeBox(props) {
    
    return <Box mb={3}>
        <textarea style={{width: "100%", height: "250px"}}>{props.children}</textarea>
    </Box>

}

CodeBox.propTypes = {children: PropTypes.node};

const BannerList = () => {

    return <Box mt={3}>
        <TaMedBody>Det går att välja om man vill ha runda eller fyrkantiga hörn på sina banners</TaMedBody>
        <TaMedIntermediateHeading>Huvudbanner</TaMedIntermediateHeading>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <div className="ta-med-widget ta-med-widget--rounded">
                    <a className="ta-med-widget__brand" href="https://tamed.se/" target="_blank">Ta med</a>
                    <div className="ta-med-widget__title">Köp take away, utan kö eller kassabetalning.</div>
                    <div className="ta-med-widget__download">
                        <a className="se.tamed.banner1.android"
                           href='https://play.google.com/store/apps/details?id=se.bitzeal.tamed'>
                            <img alt="Ta med Android" style={{height: "60px"}}
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/></a>
                        <a className="ta-med-widget__badge se.tamed.banner1.ios"
                           href='https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494'><img
                            alt="Ta med iOS" style={{height: "40px", margin: "10px"}}
                            src='/images/download_on_app_store.png'/></a>
                    </div>
                    {/*<DownloadButtons/>*/}
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>


            </Grid>
            <Grid item xs={12}>
                <TaMedBody>{"Placera denna kod i <head>-sektionen"}</TaMedBody>
                <CodeBox>
                    {`<link type="text/css" rel="stylesheet" href="https://tamed.se/css/ta-med-widget.css"/>`}
                </CodeBox>
                <TaMedBody>
                Placera denna kod där du vill ha widgeten. Du kan också ändra texten
                </TaMedBody>
                <CodeBox>
                    {`<div class="ta-med-widget ta-med-widget--rounded">
    <a class="ta-med-widget__brand" href="https://tamed.se/" target="_blank">Ta med</a>
    <div class="ta-med-widget__title">Köp take away, utan kö eller kassabetalning.</div>
    <div class="ta-med-widget__download">
        <a class="se.tamed.banner1.android"
           href='https://play.google.com/store/apps/details?id=se.bitzeal.tamed'>
            <img alt="Ta med Android" style="height: 60px" src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/></a>
        <a class="ta-med-widget__badge se.tamed.banner1.ios" href='https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494'><img
                alt="Ta med iOS" style="height: 40px; margin: 10px;"
                src='https://tamed.se/images/download_on_app_store.png'/></a>
    </div>
</div>
<script defer src="https://tamed.se/script/ta-med-widget.js"/>`}
                </CodeBox>
            </Grid>
        </Grid>
        <TaMedIntermediateHeading>Mindre variant</TaMedIntermediateHeading>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <div className="ta-med-widget--small ">
                    <div
                        style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 !important"}}>
                        <a className="ta-med-widget__brand--small" href="https://tamed.se/" target="_blank">Ta med</a>
                        <div className="ta-med-widget__body--small"><span style={{fontWeight: 500}}>Slipp köa!</span><br/>
                            Köp take&nbsp;away i appen
                        </div>
                        <div className="ta-med-widget__download--small">
                            <a className="se.tamed.banner1.android"
                               href='https://play.google.com/store/apps/details?id=se.bitzeal.tamed'>
                                <img alt="Ta med Android" style={{height: "45px"}}
                                     src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/></a>
                            <a className="ta-med-widget__badge se.tamed.banner1.ios"
                               href='https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494'><img
                                alt="Ta med iOS" style={{height: "30px", margin: "8px"}}
                                src='https://tamed.se/images/download_on_app_store.png'/></a>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}/>

            <Grid item xs={12}>
                <TaMedBody>{"Placera denna kod i <head>-sektionen"}</TaMedBody>
                <CodeBox>
                    {`<link type="text/css" rel="stylesheet" href="https://tamed.se/css/ta-med-widget.css"/>`}
                </CodeBox>
                <TaMedBody>
                    Placera denna kod där du vill ha widgeten. Du kan också ändra texten
                </TaMedBody>
                <CodeBox>
                    {`<div class="ta-med-widget--small ">
        <div style="display: flex; align-items: center; justify-content: space-between; margin: 0 !important">
        <a class="ta-med-widget__brand--small" href="https://tamed.se/" target="_blank">Ta med</a>
            <div style="" class="ta-med-widget__body--small"><span style="font-weight: 500">Slipp köa!</span><br/>Köp take&nbsp;away i appen</div>
            <div class="ta-med-widget__download--small">
                <a class="se.tamed.banner2.android"
                   href='https://play.google.com/store/apps/details?id=se.bitzeal.tamed'>
                    <img alt="Ta med Android" style="height: 45px"
                         src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/></a>
                <a class="ta-med-widget__badge se.tamed.banner2.ios" href='https://apps.apple.com/se/app/ta-med-take-away-lunch/id1537056494'><img
                        alt="Ta med iOS" style="height: 30px; margin: 8px;"
                        src='https://tamed.se/images/download_on_app_store.png'/></a>
            </div>
        </div>
    </div>
<script defer src="https://tamed.se/script/ta-med-widget.js"/>`}
                </CodeBox>
            </Grid>
        </Grid>
    </Box>
}
export default function Banners() {
    const isMdUp = useMediaQuery(grayTheme.breakpoints.up('md'));

    return (
        <MuiThemeProvider theme={grayTheme}>
            <CssBaseline/>
            <Helmet>
                <title>Ta Med - Beställ Take Away enkelt och betala med Swish</title>
                <meta name="description"
                      content="Ta Med är en mobilapp där du enkelt beställer Take Away från restauranger i din närhet. Betala med swish i appen och hämta upp."/>
                <link
                    type="text/css"
                    rel="stylesheet"
                    href="/css/ta-med-widget.css"/>

                <script defer src="/script/ta-med-widget.js"/>
            </Helmet>

            <NavMenuPublic>
                <Container maxWidth={"lg"}>
                    <Box mb={8} mx={isMdUp ? 10 : 1}>
                        <TaMedHeading>
                            Banners och knappar
                        </TaMedHeading>

                    </Box>
                </Container>
            </NavMenuPublic>
            <Container maxWidth={"lg"}>
                <Box mx={isMdUp ? 10 : 1}>


                    <BannerList/>

                    <Box mt={10}>
                        <Grid container>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </MuiThemeProvider>
    );
}
