import React, {useState} from 'react';
import {MuiThemeProvider, useMediaQuery} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {TaMedHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {grayTheme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import TextField from "@material-ui/core/TextField";
import * as PropTypes from "prop-types";
import NavMenuPublic from "./NavMenuPublic";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import {dtoToRest, setCachedRestaurants} from "./RestaurantContext";

const textLinkStyle = makeStyles({
    root: { color: 'inherit', textDecoration: 'inherit'}, // a style rule
});

function BadPrinterListEntry(props) {
    const classes = textLinkStyle();

    return <Box mx={4}>
            {props.item.printerId}
    </Box>

}

function BadPrinters(props) {
    if (props.printers.length == 0)
        return <></>
    
    return <>
        Bad printers:
        {(props.printers ?? []).map(item => (
            <BadPrinterListEntry item={item} key={item.printerId}/>
        ))}
    </>
}

function RestaurantListEntry(props) {
    const classes = textLinkStyle();
    
    return <Box my={2} mx={2}>
        {/*<Link  to={'/take-away-meny/' + props.item.slug} className={classes.root}>*/}
        <Typography variant="h6">
        {props.item.restaurantName}
        </Typography>
        <BadPrinters printers={props.item.badPrinters}></BadPrinters>
    {/*</Link>*/}
    </Box>
    
}

RestaurantListEntry.propTypes = {item: PropTypes.any};

const RestaurantList = (props) =>
{
    
    return <Box mt={3}>
<Typography variant={"h5"}>
Restaurants with current issues:
    </Typography>
            {(props.restaurants.badRestaurants ?? []).map(item => (
                <RestaurantListEntry item={item} key={item.restaurantId}/>
            ))}
    </Box>
}

const LoadingBadge = () =>
{
    return <><Box mt={4}>Laddar...</Box></>
}


function CreateNewRestaurant() {
    const [rName, setRName] = React.useState("");

    function handleRNameChange(event) {
        setRName(event.target.value);
    }

    function onCreateRestaurant() {
        firebase.auth().currentUser.getIdToken()
            .then(token => {
                const payload = {
                    'name': rName
                  
                }

                fetch('api/sysop/createrestaurant', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(payload)
                })
                    .then(async response => {
                        if (!response.ok) {
                            alert("Request failed")
                        }
                        else
                        {
                         setRName('');
                        }
                    })
                    .catch(error => {
                        alert("Network error")
                    });
            });

    }

    return <>
        <Typography variant={"h5"}>Add new restaurant</Typography>
        <Box my={2} mx={2}>
            {/*<Link  to={'/take-away-meny/' + props.item.slug} className={classes.root}>*/}
            <Grid container spacing={2}>
                <Grid item>
                    <TextField id="standard-basic" label="Restaurant Name" value={rName} onChange={handleRNameChange}/>
                </Grid>
                {/*<Typography variant="h6">*/}
                {/*    Name:*/}
                {/*</Typography>*/}
                <Grid item>
                    <Button variant={"contained"} onClick={onCreateRestaurant}>Create new restaurant</Button>
                </Grid>
            </Grid>
        </Box>
    </>;
}

function RefundAnyOrder() {
    const [orderId, setOrderId] = React.useState("");

    function handleRNameChange(event) {
        setOrderId(event.target.value);
    }

    async function onRefund() {
        const token = await firebase.auth().currentUser.getIdToken();

        fetch('api/sysop/refundAnyOrder?orderId=' + orderId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            // body: JSON.stringify(payload)
        })
            .then(async response => {
                if (!response.ok) {
                    alert("Request failed")
                } else {
                    setOrderId('');
                }
            })
            .catch(error => {
                alert("Network error")
            });

    }
    
    return <>
        <Typography variant={"h5"}>Refund any order by id</Typography>
        <Box my={2} mx={2}>
            {/*<Link  to={'/take-away-meny/' + props.item.slug} className={classes.root}>*/}
            <Grid container spacing={2}>
                <Grid item>
                    <TextField id="standard-basic" label="Order id" value={orderId} onChange={handleRNameChange}/>
                </Grid>
                {/*<Typography variant="h6">*/}
                {/*    Name:*/}
                {/*</Typography>*/}
                <Grid item>
                    <Button variant={"contained"} onClick={onRefund}>Refund</Button>
                </Grid>
            </Grid>
        </Box>
    </>;
}

const DynamicView = (props) => {
    const [restaurantStatus, setPrinterStatus] = useState(undefined);

    const reloadrestaurant = async () => {
        const token = await props.user.getIdToken();
                
        const restaurantTask = fetch('/api/sysop/restaurantstatus', {
            headers: {'Authorization': `Bearer ${token}`}
        });

        const data = await (await restaurantTask).json();
        console.log(data);
        setPrinterStatus(data);
    }
    
    if (restaurantStatus === undefined) {
        setPrinterStatus(null);

        // Reload restaurant
        reloadrestaurant().then();
    }

    if (!restaurantStatus) {
        return <LoadingBadge/>
    }

    return <>
        <RestaurantList restaurants={restaurantStatus}/>
        <Box my={4}><Divider/></Box>
        <CreateNewRestaurant/>
        <Box my={4}><Divider/></Box>
        <RefundAnyOrder/>
    </>
}

const NotAuthenticated = (props) => {
    return <>
        <Box mt={4}>
            <div><TaMedHeading>Du är inte inloggad</TaMedHeading></div>
        </Box>
    </>;
}


export default function SysOp() {
    const isMdUp = useMediaQuery(grayTheme.breakpoints.up('md'));

        return (
            <MuiThemeProvider theme={grayTheme}>
                <CssBaseline/>
                

                <NavMenuPublic>
                    <Container maxWidth={"md"}>
                        <Box mb={8} mx={isMdUp ? 10 : 1}>
                            <TaMedHeading>
                                Ta Med
                            </TaMedHeading>

                        </Box>
                    </Container>
                </NavMenuPublic>
                <Container maxWidth={"md"}>
                    <Box mx={isMdUp ? 10 : 1}>


                        <FirebaseAuthConsumer>
                        {({isSignedIn, user, providerId}) => {
                            if (!isSignedIn) {
                                return <NotAuthenticated/>
                            }
                            else {
                                return <DynamicView user={user}/>
                            }
                        }
                        }
                    </FirebaseAuthConsumer>

                      
                    
                    <Box mt={10}>
                        <Grid container>
                            
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </MuiThemeProvider>
        );
}
