import React, {useEffect, useState} from 'react';
import NavMenuAdmin from './NavMenuAdmin';
import Container from "@material-ui/core/Container"
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {
    dtoToRest,
    getCachedRestaurants,
    RestaurantContext,
    restaurantEmitter,
    setCachedRestaurants
} from "./RestaurantContext";

function Content(props) {
    const [lastUser, setLastUser] = useState(props.user);
    const [selectedRestaurant, setSelectedRestaurant] = useState(undefined);
    const [restaurants, setRestaurants] = useState(undefined);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        const onRestaurantChanged = () => {
            const cachedRestaurants = getCachedRestaurants();
            setRestaurants(cachedRestaurants);
            if (cachedRestaurants) {
                if (selectedRestaurant) {
                    const found = cachedRestaurants.find(el => el.id === selectedRestaurant.id);
                    setSelectedRestaurant(found);
                }
                else
                {
                    setSelectedRestaurant(cachedRestaurants.length > 0 ? cachedRestaurants[0] : null);
                }
            }
        };
        
        restaurantEmitter.on('changed', onRestaurantChanged);

        return () => {
            restaurantEmitter.removeListener('changed', onRestaurantChanged);
        };
    });

    async function reloadRestaurants() {
        // Reload restaurant
        const token = await props.user.getIdToken();

        const restaurantsTask = fetch('api/restaurant/getAllForAdmin', {
            headers: {'Authorization': `Bearer ${token}`}
        });


        const data2 = await (await restaurantsTask).json();
        const mappedData2 = data2.map(dtoToRest);

        setCachedRestaurants(mappedData2);
    }

    if (props.user !== lastUser || selectedRestaurant === undefined)
    {
        setLastUser(props.user);
        setSelectedRestaurant(null);
        setRestaurants(null);

        if (props.user)
        {
            reloadRestaurants().then(r => {});
        }
    }

    const childrenWithProps = React.Children.map(props.children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { selectedRestaurant: selectedRestaurant });
        }
        return child;
    });
    
    const onRestaurantSelected = (id) => {
        setSelectedRestaurant(restaurants.find(el => el.id === id));

        // Update restaurant
        if (props.user)
        {
            reloadRestaurants().then(r => {});
        }
    }
    
    return <>
        <NavMenuAdmin restaurant={selectedRestaurant} user={props.user} restaurants={restaurants} onRestaurantChanged={onRestaurantSelected} />
       <Container >
           <RestaurantContext.Provider value={selectedRestaurant}>
           {childrenWithProps}
           </RestaurantContext.Provider>
       </Container>
   </>;

}

export default function Layout(props) {

    return <FirebaseAuthConsumer>
        {({isSignedIn, user, providerId}) => {
            return <Content user={user} children={props.children}/>
        }}  
    </FirebaseAuthConsumer>
    
}
