import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";

export const EditDish = (props) => {
    const initialState = props.item || {
        name: '',
        description: '',
        price: '',
        enabled: true,
        id: 0
    };
    const [value, setValue] = React.useState(initialState);

    // string is not empty and not just whitespace
    const dishNameIsOk = (/\S/.test(value.name));
    const minPrice = 1;
    const priceIsOk = parseInt(value.price) >= minPrice;
    const priceHasError = (/\S/.test(value.price)) && (! priceIsOk);
    const buttonEnabled = dishNameIsOk && priceIsOk;

    const handleChangeName = event => {
        const newValue = Object.assign(
            {},
            value,
            {name: event.target.value},
        )

        setValue(newValue);
    };

    const handleChangeDescription = event => {
        const newValue = Object.assign(
            {},
            value,
            {description: event.target.value},
        )

        setValue(newValue);
    };

    const handleChangePrice = event => {
        const priceString = /\d/.test(event.target.value) ? parseInt(event.target.value).toString() : "";
        
        const newValue = Object.assign(
            {},
            value,
            {price: priceString},
        )

        setValue(newValue);
    };


    function handleSubmit(event) {
        // Make sure price is a number
        const newValue = Object.assign(
            {},
            value,
            {price: parseInt(value.price)},
        )
        
        props.onSubmit(newValue);

        event.preventDefault();
    }

    function handleCancel(event) {
        props.onCancel(value);

        event.stopPropagation();
    }

    return (<>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}><TextField autoFocus fullWidth={true} id="dish-name" label="Maträtt" value={value.name}
                                              onChange={handleChangeName} variant="filled"/></Grid>
                <Grid item xs={12}><TextField fullWidth={true} id="dish-additional" label="Ev ytterligare text"
                                              value={value.description} onChange={handleChangeDescription}
                                              variant="filled"/></Grid>
                {/*<input type="text" value={value} onChange={handleChange} />*/}
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end" direction="row">
                        <Grid item xs={4}><TextField error={priceHasError} fullWidth={true} id="dish-price" label="Pris"
                                                     value={value.price} onChange={handleChangePrice}
                                                     variant="filled"/></Grid>
                        <Grid item xs={8}><Box display={"flex"} justifyContent={"flex-end"}>
                            <Button disabled={! buttonEnabled} variant={"contained"} type="submit">Spara</Button>
                            <Box ml={1}><Button onClick={handleCancel} variant={"text"}>Avbryt</Button></Box>
                        </Box></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form></>)
}

function DishNameDescriptionAndPrice(props) {
    return <Grid container>
        <Grid item xs={9}>
            <ListItemText primary={props.item.name} secondary={props.item.description}/>
        </Grid>
        <Grid item xs={3}>
            <Box p={2}><Typography variant={"h6"}>{props.item.price}</Typography></Box>
        </Grid>
    </Grid>;
}

function DishEntryButtons(props) {
    return <Grid
        container
        direction={"column"}
        justify={"center"}
        alignItems="center"
        spacing={0}
    >
        <Grid item>
            <Box px={1} pt={1} display={props.onMoveUp ? "block" : "none"}>
                <IconButton onClick={(event) => {
                    event.stopPropagation();
                    props.onMoveUp(props.item.id);
                }} edge="end"
                            aria-label="move up">
                    <ArrowUpwardIcon/>
                </IconButton>
            </Box>
        </Grid>
        <Grid item>
            <Box px={1} pb={1}>
                <IconButton onClick={(event) => {
                    event.stopPropagation();
                    props.onDeleteItem(props.item.id)}} edge="end"
                            aria-label="delete">
                    <DeleteIcon/>
                </IconButton>
            </Box>
        </Grid>
    </Grid>;
}

export const DailyDishListItem = (props) => {
    const [editing, setEditing] = React.useState(false);

    function listItemClicked() {
        if (editing)
            return;

        setEditing(true);
    }

    function itemSaved(newValue) {
        setEditing(false);
        props.onUpdate(newValue);
    }

    return <ListItem onClick={() => listItemClicked()}>
            {!editing && 
                <Grid container>
                    <Grid item xs={10}>
                        <Box pb={1}>
                        <CardActionArea>
                            <DishNameDescriptionAndPrice item={props.item}/>
                        </CardActionArea>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <ListItemSecondaryAction>
                            <DishEntryButtons item={props.item} onMoveUp={props.onMoveUp} onDeleteItem={props.onDeleteItem} />
                        </ListItemSecondaryAction>

                    </Grid>
                </Grid>
            }
            {editing && <EditDish item={props.item} onSubmit={itemSaved} onCancel={
                () => {
                    setEditing(false);
                }
            }/>}
        </ListItem>
}

export const ALaCarteDishListItem = (props) => {
    const [editing, setEditing] = React.useState(false);
    const [active, setActive] = React.useState(props.item.enabled);
    
    function listItemClicked() {
        if (editing)
            return;

        setEditing(true);
    }

    function itemSaved(newValue) {
        setEditing(false);
        props.onUpdate(newValue);
    }

    function handleActiveChange(event) {
        setActive(event.target.checked);
        const newValue = Object.assign(
            {},
            props.item,
            {enabled: event.target.checked},
        )

        props.onUpdate(newValue);
    }
    
    return <ListItem onClick={() => listItemClicked()}>
        {!editing &&
        <Grid container>
            <Grid item xs={10}>
                <Box pb={1}>
                <CardActionArea>
                    <DishNameDescriptionAndPrice item={props.item}/>
                </CardActionArea>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={active} onChange={handleActiveChange} onClick={e => e.stopPropagation()}/>}
                            // label="Visa i menyn"
                         label={""}/>
                    </FormGroup>
                </FormControl>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <ListItemSecondaryAction>
                    <DishEntryButtons item={props.item} onMoveUp={props.onMoveUp} onDeleteItem={props.onDeleteItem} />
                </ListItemSecondaryAction>

            </Grid>
        </Grid>
        }
        {editing && <EditDish item={props.item} onSubmit={itemSaved} onCancel={
            () => {
                setEditing(false);
            }
        }/>}
    </ListItem>
}