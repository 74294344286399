import React from 'react';
import {Button, Card, MuiThemeProvider, useMediaQuery} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {TaMedBody, TaMedHeading, TaMedIntermediateHeading} from "./ta-med-styles/TaMedStyles";
import Box from "@material-ui/core/Box";
import {grayTheme, greenTheme, theme} from "./helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Helmet} from "react-helmet";
import NavMenuPublic from "./NavMenuPublic";
import {DownloadButtons} from "./DownloadButtons";
import Hidden from "@material-ui/core/Hidden";


function StepCard(props) {
    const greenCardStyle = {
        // color: '#f3c0aa',
        // minHeight: isXs?'100px': '430px',
        backgroundColor: theme.palette.primary.dark,
        borderRadius: '16px'
    };

    return <Card style={greenCardStyle}>
        {props.children}
    </Card>;
}

export default function Home(props) {

    const isXs = useMediaQuery(grayTheme.breakpoints.down('xs'));

    const screenshotStyle = {
            height: '234px',
            verticalAlign: 'bottom'
        };
        
        return (
            <MuiThemeProvider theme={greenTheme}>
                <CssBaseline/>
                <Helmet>
                    <title>Ta Med - Beställ Take Away enkelt och betala med Swish</title>
                    <meta name="description" content="Ta Med är en mobilapp där du enkelt beställer Take Away från restauranger i din närhet. Betala med swish i appen och hämta upp." />
                </Helmet>

                <NavMenuPublic>
                    <Container>
                        <Box mb={12}><Grid container>
                            <Grid item xs={12} sm={6}>
                                <Hidden xsDown>
                                <TaMedHeading>
                                    Take away utan kö och kassabetalning
                                </TaMedHeading>
                                </Hidden>
                                <Hidden smUp>
                                    <img src={"images/iphones3.png"} alt={"Ta med app"} style={{
    maxWidth: "100%",
    maxHeight: "352px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
}}/>
                                    <Typography variant='h5' style={{fontSize: '34px'}}>Take away utan kö och kassabetalning</Typography>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} sm={6}/>
                        </Grid>
                        </Box>

                    </Container>
                </NavMenuPublic>

                <Box><Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box my={4}>
                                {/*<TaMedHeading>Take away lunch</TaMedHeading>*/}
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={4}>
                                        <StepCard>
                                            <Grid
                                                container
                                                direction="column"
                                                alignItems="center"
                                                style={{minHeight: isXs ? '216px' : '436px'}}
                                            >
                                                <Grid item>
                                                    <Box mx={3} mt={6}>
                                                        <Typography variant={"h4"}>1. Hämta appen</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box mt={isXs ? 3 : 12}>
                                                        <DownloadButtons/>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </StepCard>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <StepCard>
                                            <Grid
                                                container
                                                direction="column"
                                                justify="space-between"
                                                alignItems="center"
                                                style={{minHeight: isXs ? '216px' : '436px'}}
                                            >
                                                <Grid item>
                                                    <Box mx={3} mt={6}>
                                        <Typography variant={"h4"}>2. Välj och betala<br/>med Swish</Typography>
                                            </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box mt={3}>
                                        <img src={"images/app-restaurang.png"} style={screenshotStyle} alt={"köp take away med swish"}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </StepCard>
                                     </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <StepCard>
                                            <Grid
                                                container
                                                direction="column"
                                                justify="space-between"
                                                alignItems="center"
                                                style={{minHeight: isXs ? '216px' : '436px'}}
                                            >
                                                <Grid item>
                                            <Box mx={3} mt={6}>
                                        <Typography variant={"h4"}>3. Hämta upp</Typography>
                                            </Box>
                                                </Grid>
                                                
                                            <Grid item>
                                                <Box mt={3}>
                                        <img src={"images/app-take-away.png"} style={screenshotStyle} alt={"hämta take away"}/>
                                                </Box>
                                            </Grid>
                                            </Grid>
                                        </StepCard>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={10} mb={4}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box m={2} display={"flex"} justifyContent={"center"}>
                                    <Button component={"a"} href="/restauranger">Menyer</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Box m={2} display={"flex"} justifyContent={"center"}>
                                <Button component={"a"} href="mailto:info@tamed.se">Kontakta oss</Button>
                            </Box>
                        </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box m={2} display={"flex"} justifyContent={"center"}>
                                    <Button component={Link} to={"/privacy"}>Integritetspolicy</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                </Box>
            </MuiThemeProvider>
        );
}
